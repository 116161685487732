<div aria-label="FAQ header" class="tw-realtive tw-w-[100%] tw-h-auto tw-min-h[154px] tw-bg-white tw-z-10">
  <div class="scoot-container tw-mx-auto tw-py-[30px]">
    <div
      class="tw-flex tw-flex-wrap xs:tw-gap-[22px] xs:tw-items-start xs:tw-justify-start xxs:tw-items-center xxs:tw-pl-8"
    >
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]">
          <span
            aria-label="FAQ header label"
            class="tw-font-bold tw-text-[26px] tw-text-stone-900 tw-font-['Montserrat'] tw-leading-normal"
          >
            {{ 'label.banner.FAQ.label' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Body -->
<div aria-label="Page Content" class="page-content">
  <div class="scoot-container tw-mx-auto tw-mb-20 tw-mt-[20px]">
    <div
      aria-label="FAQ Scoot Staff Travel section"
      class="tw-mb-[30px] xs:tw-items-start xs:tw-justify-start xxs:tw-items-center xxs:tw-justify-center xxs:tw-px-[10px]"
    >
      <div class="tw-relative tw-overflow-hidden tw-border-2 tw-boxShadow-shadows tw-rounded-[20px]">
        <!-- Header -->
        <div
          aria-label="FAQ Scoot Staff Travel header"
          class="tw-px-[20px] tw-py-5 tw-bg-yellow-100"
          (click)="onBodyToggle()"
        >
          <div class="tw-flex tw-items-center tw-justify-between">
            <div
              aria-label="FAQ Scoot Staff Travel title"
              class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]"
            >
              <img
                aria-label="FAQ Scoot Staff Travel icon"
                class="tw-w-[36px] tw-h-[36px]"
                src="/assets/images/logo.svg"
                alt="Scoot icon"
              />
              <span
                aria-label="FAQ Scoot Staff Travel title label"
                class="tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-normal"
                >{{ 'label.faq.scootstafftravel.header' | translate }}</span
              >
            </div>
            <div class="tw-flex tw-items-center tw-justify-center">
              <img
                class="tw-cursor-pointer tw-transition-transform tw-w-[24px] tw-h-[24px]"
                [ngClass]="{ 'tw-rotate-180': !isBodyShow }"
                aria-label="FAQ Scoot Staff Travel chevron arrow icon"
                src="/assets/icons/svg/Chev-Arrow.svg"
                alt="FAQ chevron arrow"
              />
            </div>
          </div>
        </div>

        <!-- Body -->
        <div
          *ngIf="isBodyShow"
          aria-label="FAQ Scoot Staff Travel body"
          class="xs:tw-py-[30px] xs:tw-px-[50px] xxs:tw-py-[20px] xxs:tw-px-[15px] tw-flex tw-items-center tw-justify-start tw-bg-white tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-10"
        >
          <div class="tw-w-full tw-flex tw-flex-wrap xs:tw-justify-between xs:tw-gap-y-[60px]">
            <!-- FAQ Staff Travel Policy -->
            <div
              aria-label="FAQ Staff Travel Policy group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ Staff Travel Policy logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.StaffTravelPolicyLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/pack-cabin-baggage_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ Staff Travel Policy label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.staff-travel-policy.label' | translate }}
                </div>
                <div
                  aria-label="FAQ Staff Travel Policy text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.staff-travel-policy.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ Staff Travel Guide -->
            <div
              aria-label="FAQ Staff Travel Guide group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ Staff Travel Guide logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.StaffTravelGuideLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/check-in_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ Staff Travel Guide label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.staff-travel-guide.label' | translate }}
                </div>
                <div
                  aria-label="FAQ Staff Travel Guide text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.staff-travel-guide.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ Info -->

            <div
              aria-label="FAQ Info group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ Info logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.FAQInfoLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/faq-airplane.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ Info label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.banner.FAQ.label' | translate }}
                </div>
                <div
                  aria-label="FAQ Info text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.label.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ SCOOTFORSURE -->

            <div
              aria-label="FAQ SCOOTFORSURE group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ SCOOTFORSURE logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.ScootForSureLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/print-boarding-pass_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ SCOOTFORSURE label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.book-flight.scootforsure.label' | translate }}
                </div>
                <div
                  aria-label="FAQ SCOOTFORSURE text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.scootforsure.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ ChopeMySeat -->

            <div
              aria-label="FAQ ChopeMySeat group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ ChopeMySeat logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.ChopeMySeatLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/economy_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ ChopeMySeat label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.chopemyseat.label' | translate }}
                </div>
                <div
                  aria-label="FAQ ChopeMySeat text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.chopemyseat.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ ESC Feedback -->
            <div
              aria-label="FAQ ESC Feedback Form group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ ESC Feedback Form logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.ESCFeedbackFormLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/phone_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ ESC Feedback Form label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.esc-feedback-form.label' | translate }}
                </div>
                <div
                  aria-label="FAQ ESC Feedback Form text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.esc-feedback-form.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ Chat with Kaki -->
            <div
              aria-label="FAQ Chat with Kaki group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ Chat with Kaki logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.ChatWithKakiLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/kaki-default.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ Chat with Kaki label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.chat-with-kaki.label' | translate }}
                </div>
                <div
                  aria-label="FAQ Chat with Kaki text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.chat-with-kaki.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ Chat with Ozy -->
            <div
              aria-label="FAQ Chat with Ozy group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px]"
            >
              <div
                aria-label="FAQ Chat with Ozy logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqScootStaffTravelLinks.ChatWithOzyLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/ozy-default.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ Chat with Ozy label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.chat-with-ozy.label' | translate }}
                </div>
                <div
                  aria-label="FAQ Chat with Ozy text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.chat-with-ozy.text' | translate }}
                </div>
              </div>
            </div>
            <div
              [hidden]="isMobileView"
              la
              class="tw-realtive tw-h-auto xs:tw-flex-wrap xs:tw-w-[290px] xxs:tw-w-full xs:tw- xs:tw-justify-startgap-y-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px]"
            >
              <div class="tw-realtive tw-flax tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"></div>
              <div class="tw-w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      aria-label="FAQ Interlines section"
      class="tw-mb-[30px] xs:tw-items-start xs:tw-justify-start xxs:tw-items-center xxs:tw-justify-center xxs:tw-px-[10px]"
    >
      <div class="tw-relative tw-overflow-hidden tw-border-2 tw-boxShadow-shadows tw-rounded-[20px]">
        <!-- Header -->
        <div
          aria-label="FAQ Interlines header"
          class="tw-px-[20px] tw-py-5 tw-bg-yellow-100"
          (click)="onInterlineBodyToggle()"
        >
          <div class="tw-flex tw-items-center tw-justify-between">
            <div aria-label="FAQ Interlines title" class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]">
              <img
                aria-label="FAQ Interlines icon"
                class="tw-w-[36px] tw-h-[36px]"
                src="/assets/icons/svg/airplane-white.svg"
                alt="Scoot icon"
              />
              <span
                aria-label="FAQ Interlines title label"
                class="tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-normal"
                >{{ 'label.faq.interlines.header' | translate }}</span
              >
            </div>
            <div class="tw-flex tw-items-center tw-justify-center">
              <img
                class="tw-cursor-pointer tw-transition-transform tw-w-[24px] tw-h-[24px]"
                [ngClass]="{ 'tw-rotate-180': !isInterlineBodyShow }"
                aria-label="FAQ Interlines chevron arrow icon"
                src="/assets/icons/svg/Chev-Arrow.svg"
                alt="FAQ chevron arrow"
              />
            </div>
          </div>
        </div>

        <!-- Body -->
        <div
          *ngIf="isInterlineBodyShow"
          aria-label="FAQ Interlines body"
          class="xs:tw-py-[30px] xs:tw-px-[50px] xxs:tw-py-[20px] xxs:tw-px-[15px] tw-flex tw-items-center tw-justify-start tw-bg-white tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-10"
        >
          <div class="tw-w-full tw-flex tw-flex-wrap xs:tw-justify-between xs:tw-gap-y-[60px]">
            <!-- FAQ SQ Interline Policy and FAQ -->
            <div
              aria-label="FAQ SQ Interline Policy and FAQ group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ SQ Interline Policy and FAQ logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqInterlinesLinks.SQInterlinePolicyAndFaqLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/logo-singaporeairlines.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ SQ Interline Policy and FAQ label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.sq-interline-policy-and-faq.label' | translate }}
                </div>
                <div
                  aria-label="FAQ SQ Interline Policy and FAQ text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.sq-interline-policy-and-faq.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ LH Interline Policy and FAQ -->

            <div
              aria-label="FAQ LH Interline Policy and FAQ group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px] xs:tw-border-b-0 xxs:tw-border-b-2"
            >
              <div
                aria-label="FAQ LH Interline Policy and FAQ logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqInterlinesLinks.LHInterlinePolicyAndFaqLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/lufthansa_logo.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ LH Interline Policy and FAQ label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.lh-interline-policy-and-faq.label' | translate }}
                </div>
                <div
                  aria-label="FAQ LH Interline Policy and FAQ text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.lh-interline-policy-and-faq.text' | translate }}
                </div>
              </div>
            </div>

            <!-- FAQ SQ or LH ESC Feedback -->

            <div
              aria-label="FAQ SQ or LH ESC Feedback Form group"
              class="tw-flex tw-h-auto xs:tw-flex-wrap xs:tw-items-start xs:tw-justify-start xs:tw-w-[290px] xxs:tw-w-full xs:tw-gap-[10px] xxs:tw-gap-[20px] xs:tw-p-0 xxs:tw-py-[20px]"
            >
              <div
                aria-label="FAQ SQ or LH ESC Feedback Form logo"
                class="tw-flex tw-items-center tw-justify-center xs:tw-w-full xxs:tw-min-w-[55px]"
              >
                <a [href]="faqInterlinesLinks.SQorLHInterlinePolicyAndFaqLink">
                  <img
                    class="xs:tw-w-auto xs:tw-h-[75px] xxs:tw-w-[55px] xxs:tw-h-auto"
                    src="/assets/icons/svg/phone_2.0.svg"
                  />
                </a>
              </div>
              <div class="tw-relative tw-w-full">
                <div
                  aria-label="FAQ SQ or LH ESC Feedback Form label"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
                >
                  {{ 'label.faq.sq-or-lh-esc-feedback-form.label' | translate }}
                </div>
                <div
                  aria-label="FAQ SQ or LH ESC Feedback Form text"
                  class="xs:tw-text-center tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
                >
                  {{ 'label.faq.esc-feedback-form.text' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
