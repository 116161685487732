import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppConstants } from '../../../app.constants';
import { DropdownConfig, DropdownOption } from './dropdown.model';

@Component({
  selector: 'tr-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  private _config!: DropdownConfig;
  private _pattern?: RegExp;

  options!: DropdownOption[];

  @ViewChild('dropdownElement') dropdownElement!: ElementRef;
  @ViewChild('dropdownValueContainer') dropdownValueContainer!: ElementRef;
  @ViewChild('dropdownInput') dropdownInput!: ElementRef;

  @Input() showLoader!: boolean;
  @Input()
  get config(): DropdownConfig {
    return this._config;
  }
  set config(data: DropdownConfig) {
    if (data) {
      this._config = data;
      this.options = this._config.options;
      if (!this._config.options.find((option) => option.value === this.optionSelect?.value)) {
        this.config.control.setValue(null);
      }
    }
  }

  showDropdownOptions = false;
  keyword: string = '';
  searchText: string = '';
  disable: boolean = false;
  optionSelect: DropdownOption | undefined;

  ngOnInit() {
    if (this.optionSelect?.value) {
      this.options = this.dropdownOptions;
    }
  }

  onChangeOption = () => {};
  onTouched = () => {};

  writeValue(code: any) {
    this.keyword = code;
    this.optionSelect = this.config.options.find((option) => option.code == this.keyword);
    this.searchText = this.optionSelect?.value ?? '';
  }

  registerOnChange(onChangeOption: any) {
    this.onChangeOption = onChangeOption;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onFocusedOut(event?: any) {
    this.config.control?.markAsTouched();
    const value = event?.value;
    this.showDropdownOptions = false;
    const mathValue = this.config.options.find((option) => option.value === value);
    if (mathValue === undefined) {
      this.config.control.setValue(null);
      event.value = '';
    } else {
      this.config.control.setValue(mathValue.code);
    }
  }

  onFocusIn() {
    this.options = this.config.options;
  }

  onOptionClicked(value: string, code: string, isDisabled: boolean) {
    if (code !== '' && !isDisabled) {
      this.config.control?.markAsTouched();
      this.showDropdownOptions = false;
      if (this.config.control.value !== value) {
        const selectedOption = this.config.options.find((o) => o.value === value);
        if (selectedOption) {
          this.config.control.setValue(code);
        }
      }
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    if (this._pattern) {
      const value = event.key;
      const isValid = this._pattern.test(value);
      if (!isValid) {
        event.preventDefault();
      }
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
    }
  }

  setDisabledState(disable: any) {
    this.disable = disable;
  }

  updateDropdown(event: any) {
    let value: string = event.target.value;
    this.filterOptions(value);
  }

  filterOptions(value: string) {
    if (value == '') {
      this.config.control.setValue(null);
    }
    this.options = this.config.options.filter(
      (option) =>
        option.value.toLocaleLowerCase().includes(value.toLowerCase()) ||
        option.code.toLocaleLowerCase().includes(value.toLowerCase())
    );
  }

  clearSelection() {
    this.searchText = '';
    this.filterOptions('');
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (!this.dropdownElement.nativeElement.contains(target) && this.showDropdownOptions) {
      let event = this.dropdownElement.nativeElement.children[0].children[0];
      this.onFocusedOut(event);
    }
  }

  get placeholder(): string {
    if (this.keyword || this.dropdownTextValue) {
      return '';
    }
    return 'label.' + this.config.componentIdentifier + '.placeholder';
  }

  get isRequired(): boolean {
    return this.config.control.hasValidator(Validators.required);
  }

  get hasError(): boolean {
    return !this.config.control?.valid && (this.config.control?.touched || this.config.control?.dirty);
  }

  get isDisabled(): boolean {
    return this.config.control.disabled;
  }

  get dropdownTextValue(): string {
    const selectedOption = this.config.options?.find((o) => o.code === this.config.control?.value);
    if (selectedOption && !this.keyword) {
      return selectedOption.value;
    }
    return '';
  }

  get dropdownOptions(): DropdownOption[] {
    if (this.optionSelect?.value) {
      return this.config.options.filter((option) =>
        this.optionSelect?.value
          ? option.value.toLowerCase().includes(this.optionSelect?.value.toLocaleLowerCase())
          : null
      );
    }
    return this.config.options;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }
}
