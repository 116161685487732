import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlightSearchService } from '../flight-search.service';

@Component({
  selector: 'app-flight-search-modal',
  templateUrl: './flight-search-modal.component.html',
  styleUrls: ['./flight-search-modal.component.scss']
})
export class FlightSearchModalComponent {
  _showModal = false;

  @Input()
  get showModal(): boolean {
    return this._showModal;
  }
  set showModal(value: boolean) {
    this._showModal = value;
    if (value) this.setOrigFormData();
  }
  @Output() closeModal = new EventEmitter<void>();
  @Output() letsGo = new EventEmitter<void>();

  origFormData!: any

  constructor (private flightSearchService: FlightSearchService) {}

  setOrigFormData(): void {
    if (this.flightSearchService.flightSearchForm)
      this.origFormData = this.flightSearchService.flightSearchForm.getRawValue();
  }

  onCloseModal() {
    this.flightSearchService.flightSearchForm.get('isReturn')?.setValue(this.origFormData['isReturn']);
    this.flightSearchService.flightSearchForm.get('fromCityAirport')?.setValue(this.origFormData['fromCityAirport']);
    this.flightSearchService.flightSearchForm.get('toCityAirport')?.setValue(this.origFormData['toCityAirport']);
    this.flightSearchService.flightSearchForm.get('passengers')?.setValue(this.origFormData['passengers']);
    this.flightSearchService.flightSearchForm.get('departDate')?.setValue(this.origFormData['departDate']);
    this.flightSearchService.calendar.departDate = this.origFormData['departDate'];

    if (this._isReturn) {
      this.flightSearchService.flightSearchForm.get('returnDate')?.setValue(this.origFormData['returnDate']);
      this.flightSearchService.calendar.returnDate = this.origFormData['returnDate']
    }

    this.showModal = false;
    this.closeModal.emit();
  }

  onClickLetsGo() {
    this.showModal = false;
    this.letsGo.emit();
  }

  get _isReturn(): boolean {
    return this.flightSearchService.flightSearchForm.get('isReturn')?.value;
  }
}
