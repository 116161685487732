import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
  private readonly min = ' minute';
  private readonly mins = ' minutes';
  private readonly sec = ' second';
  private readonly secs = ' seconds';

  transform(timeInSecs: number): string {
    const minutes = Math.floor(timeInSecs / 60);
    const seconds = timeInSecs % 60;

    const minuteStr = minutes > 0 ? `0${minutes}` : '00';
    const secondStr = seconds < 10 ? `0${seconds}` : seconds.toString();

    const timer = `${minuteStr}:${secondStr}`;

    return (
      timer + (timeInSecs >= 120 ? this.mins : timeInSecs >= 60 ? this.min : timeInSecs >= 2 ? this.secs : this.sec)
    );
  }
}
