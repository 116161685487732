<div aria-label="Dropdown modal" aria-modal="true" role="dialog" [hidden]="!isVisible" class="modal-window-container">
  <div class="modal-container" aria-label="Modal container">
    <div class="modal-header" aria-label="Modal header">
      <div class="tw-h-[4rem]">
        <div class="modal-header-container tw-bg-yellow-100">
          <div aria-label="Modal title" class="tw-text-center tw-items-center tw-justify-center tw-font-bold">
            {{ title }} {{ formGroup.controls['dropDownField'].value }}
          </div>
          <div class="modal-close">
            <img
              aria-label="Modal close icon"
              class="tw-absolute tw-right-5 tw-top-4"
              (click)="onCloseModal()"
              src="/assets/icons/svg/exit.svg"
              alt="exit"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body" aria-label="Modal body">
      <div class="modal-body">
        <form [formGroup]="formGroup">
          <div class="tw-relative">
            <p aria-label="Modal label" class="tw-ml-2">{{ label }}</p>
            <div [class.disabled-field]="showLoader || dropdownFieldConfig.control.disabled">
              <img
                class="tw-z-30 tw-ml-2 tw-mt-[0.4rem] tw-absolute"
                src="/assets/icons/svg/map-pin.svg"
                aria-label="Dropdown mobile icon"
              />
              <tr-dropdown
                aria-label="Dropdown mobile field"
                formControlName="dropDownField"
                [config]="dropdownFieldConfig"
                [showLoader]="showLoader"
              >
              </tr-dropdown>
            </div>
            <app-tooltip
              aria-label="Destination mobile field tooltip"
              class="tw-w-full tw-h-full tw-absolute tw-bottom-0"
              [position]="'down'"
              *ngIf="showLoader"
            ></app-tooltip>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
