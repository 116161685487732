<div aria-label="Input with error" [ngClass]="{'hasError': hasError}">
    <ng-content></ng-content>
    <ng-container *ngIf="hasError">
      <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
    </ng-container>
  </div>
  <ng-template #errorMessage>
    <div class="text-errors" aria-label="input error message">
      <div class="">
        {{ errorLabel | translate }}
      </div>
    </div>
  </ng-template>
  