import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from './loader.service';
import { AppConstants } from '../app.constants';
import { ActivatedRoute } from '@angular/router';
import { SKIP_LOADER } from '../shared/model/common.model';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService, private route: ActivatedRoute) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipLoading = this.shouldSkipLoading(request.url) || request.context.get(SKIP_LOADER);

    if (skipLoading) {
      return next.handle(request);
    }

    this.loaderService.setLoading(true, request.url);
    
    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.setLoading(false, request.url);
      })
    );
  }

  private shouldSkipLoading(requestUrl: string): boolean {
    const skipLoadingParam = this.route?.snapshot?.queryParamMap?.get('skipLoading');
    return (
      !!skipLoadingParam || AppConstants.SkipLoadingUrls.some((skipLoadingUrl) => requestUrl.includes(skipLoadingUrl))
    );
  }
}
