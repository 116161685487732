import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AddVoucherRequest, SaveVoucherRequest } from '../../shared/model/finance.model';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { NotificationService } from '../../shared/components/notification/notification.service';
import { BookingService } from '../booking.service';
import { VoucherData } from '../flight/flight.model';
import { PaymentService } from '../payment/payment.service';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoucherCompoennt implements OnDestroy, OnInit {
  private readonly _destroy$ = new Subject<void>();

  vouchers$ = new BehaviorSubject<VoucherData[]>([]);
  showVoucher = true;
  totalRedeemedPoints = 0;
  totalFarePoints = 0;
  voucherList!: VoucherData[];

  constructor(
    private bookingService: BookingService,
    private notificationService: NotificationService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.vouchers$ = this.paymentService.vouchers$;
    this.paymentService.vouchers$.pipe(takeUntil(this._destroy$)).subscribe((vouchers) => {
      this.totalRedeemedPoints = 0;
      vouchers.filter(x => x.isSelectedForDelete && x.isSelected).forEach((voucher) => {
        this.totalRedeemedPoints += voucher.amount;
      });
    });
    this.bookingService.booking$.pipe(takeUntil(this._destroy$)).subscribe((booking) => {
      this.totalFarePoints = booking?.breakDown?.pointsBalanceDue ?? 0;
    });
  }

  onToggleVoucher(value: boolean): void {
    this.showVoucher = value;
  }

  hasBalanceDue(): boolean {
    return (this.totalFarePoints ?? 0) > this.totalRedeemedPoints;
  }

  onSelectVoucher(voucher: VoucherData, isSelected: boolean): void {
    voucher.isSelected = isSelected;
    if (isSelected) {
      this.totalRedeemedPoints += voucher.amount;
      voucher.isSelectedForAdd = true;
      voucher.isSelectedForDelete = false;
    } else {
      this.totalRedeemedPoints -= voucher.amount;
      voucher.isSelectedForAdd = false;
      voucher.isSelectedForDelete = true;
    }
  }

  onApply(vouchers: VoucherData[]): void {
    let addVouchers: AddVoucherRequest[] = [];
    let deleteVouchers: string[] = [];

    //Add
    const forAddVouchers = vouchers.filter((p) => p.isSelectedForAdd && !p.isAdded && p.isSelected);
    forAddVouchers.forEach((voucher) => {
      const request = {
        currencyCode: voucher.currency,
        referenceCode: voucher.voucherNumber.toString(),
        paymentMethodCode: AppConstants.VoucherPaymentCode,
        overrideRestrictions: false
      } as AddVoucherRequest;

      addVouchers.push(request);
      voucher.isAdded = true;
      voucher.isDeleted = false;
    });

    //Delete
    const forDeleteVouchers = vouchers.filter((p) => p.isSelectedForDelete && !p.isDeleted && !p.isSelected);
    forDeleteVouchers.forEach((voucher) => {
      deleteVouchers.push(voucher.voucherNumber.toString());
      voucher.isDeleted = true;
      voucher.isAdded = false;
    });

    //Save
    if (addVouchers.length > 0 || deleteVouchers.length > 0) {
      const request = {
        addVouchers: addVouchers,
        deleteVouchers: deleteVouchers
      } as SaveVoucherRequest;

      this.bookingService
        .saveVoucher$(request)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.isSuccess) {
            this.reload();
          } else {
            this.errorNotification(res.errorMessage);
          }
        });
    }
  }

  private errorNotification(errorMessage: string) {
    const self = this;
    this.notificationService.show({
      title: 'Error!',
      text: errorMessage,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Ok',
      callBack: function (isConfirm: boolean) {
        if (isConfirm) {
          self.reload();
        }
      }
    });
  }

  private reload(): void {
    this.paymentService.reloadPaymentPage$.next(true);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
