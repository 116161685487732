<div
  aria-label="Modal"
  [hidden]="!show"
  [ngClass]="{
    'tw-justify-center tw-items-center tw-flex': show
  }"
  class="tw-z-modal tw-fixed tw-top-0 tw-left-0 tw-right-0 t tw-p-4 tw-overflow-x-hidden tw-overflow-y-auto md:tw-inset-0 tw-h-[calc(100%-1rem)] tw-max-h-full"
  role="dialog"
  aria-modal="true"
  (click)="clickPopupBackground()">
>
    <div
    aria-label="Modal background"
    (click)="onCloseModal()"
    class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
    ></div>
    
    <div aria-label="Modal content" class="tw-relative tw-w-full xs:tw-w-auto tw-max-h-full" (click)="stopPropagation($event)">
      <div class="tw-relative tw-rounded-5 tw-shadow dark:tw-bg-gray-700">
        <ng-container #modal></ng-container>
      </div>
    </div>
</div>