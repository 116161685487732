import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class StaffTravelApiInterceptor implements HttpInterceptor {
  constructor(@Inject('baseUrl') private baseUrl: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      url: `${this.baseUrl}${request.url}`,
      setHeaders: this.setDefaultHeaders()
    });

    return next.handle(newRequest);
  }

  private setDefaultHeaders() {
    return {
      'Content-Type': 'application/json',
      'X-Scoot-AppSource': environment.appSource,
      'X-Scoot-Client-Version': environment.appVersion,
      'X-Scoot-Client-Environment': environment.name,
      'X-Api-Key': environment.apiKey
    };
  }
}
