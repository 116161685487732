import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DropdownMultiSelectConfig } from '../../../shared/components/dropdown-multiselect/dropdown-multiselect.component';
import { FlightSearchData } from '../../flight/flight.model';
import { AppConstants } from '../../../app.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { PassengerTypeEnum } from '../flight.enum';
import { DropdownConfig } from '../../../shared/components/dropdown/dropdown.model';

@Injectable({
  providedIn: 'root'
})
export class FlightSearchService {
  private readonly _markDisabledDate$ = new BehaviorSubject<string[]>([]);

  private _formData!: FlightSearchData | null;
  private _fromLocationConfig!: DropdownConfig;
  private _toLocationConfig!: DropdownConfig;
  private _passengerConfig!: DropdownMultiSelectConfig;
  private _canManageFirstJourney!: boolean;

  disabledDateList: string[] = [];
  flightSearchForm!: FormGroup;
  originCode: string = '';
  hasPassengerError = {
    code: ''
  };
  calendar = {
    departDate: null as string | null,
    returnDate: null as string | null
  };

  constructor() {
    this.flightSearchForm = new FormGroup({
      isReturn: new FormControl(true),
      fromCityAirport: new FormControl(null, Validators.required),
      toCityAirport: new FormControl(null, Validators.required),
      passengers: new FormControl([], [Validators.required, (c) => this.validatePassengerInput(c)]),
      departDate: new FormControl(null, Validators.required),
      returnDate: new FormControl(null, Validators.required),
      currencyCode: new FormControl(AppConstants.DefaultCurrencyCode)
    });
  }

  getMarkDisabledDate$(): Observable<string[]> {
    return this._markDisabledDate$.asObservable();
  }

  getFormData(): FlightSearchData | null {
    return this._formData;
  }

  getCanManageFirstJourney(): boolean | null {
    return this._canManageFirstJourney;
  }

  getFromLocationConfig(): DropdownConfig {
    return this._fromLocationConfig;
  }

  getToLocationConfig(): DropdownConfig {
    return this._toLocationConfig;
  }

  getPassengerConfig(): DropdownMultiSelectConfig {
    return this._passengerConfig;
  }

  setFromLocationConfig(config: DropdownConfig): void {
    this._fromLocationConfig = config ?? {};
  }

  setMarkDisabledDate$(disableDates: string[]): void {
    this._markDisabledDate$.next(disableDates);
  }

  setToLocationConfig(config: DropdownConfig): void {
    this._toLocationConfig = config ?? {};
  }

  setPassengerConfig(config: DropdownMultiSelectConfig): void {
    this._passengerConfig = config ?? {};
  }

  setCanManageFirstJourney(isDeparted: boolean): void {
    this._canManageFirstJourney = isDeparted;
  }

  setFormData(formData: FlightSearchData | null): void {
    this._formData = formData;
  }

  resetForm(): void {
    this.flightSearchForm.reset();
    this.flightSearchForm.setValue({
      isReturn: true,
      fromCityAirport: null,
      toCityAirport: null,
      passengers: [],
      departDate: null,
      returnDate: null,
      currencyCode: AppConstants.DefaultCurrencyCode
    });

    this.calendar = {
      departDate: null,
      returnDate: null
    };

    this.flightSearchForm.enable();
    this.flightSearchForm.markAsPristine();
    this.flightSearchForm.markAsUntouched();
  }

  formatDate(date: Date | null, format: string): string | null {
    if (date) return formatDate(date, format, 'en');
    return null;
  }

  formatToNgbDate(date: string): NgbDate | null {
    if (date !== '') {
      let val = new Date(formatDate(date, AppConstants.DefaultDisplayDateFormat, AppConstants.DefaultCultureCode));
      return new NgbDate(val.getFullYear(), val.getMonth() + 1, val.getDate());
    }

    return null;
  }

  formatNgbDateToDate(date: NgbDate | null): Date | null {
    return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
  }

  private validatePassengerInput(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && this.hasPassengerError.code != '') {
      return {
        error:
          this.hasPassengerError.code === PassengerTypeEnum.INFANT + PassengerTypeEnum.CHILD
            ? 'label.book-flight-search.passenger.error.inf-chd-transition'
            : 'label.book-flight-search.passenger.error.chd-adt-transition'
      };
    } else {
      return null;
    }
  }
}
