import { Injectable } from '@angular/core';
import { ChangeFlightData, FlightSelectedData, Passenger, PassengerCriteria } from './flight.model';
import { BehaviorSubject } from 'rxjs';
import { FlightClassOfServiceEnum, FlightFareTypeEnum, FlightSelectionTypeEnum } from './flight.enum';
import { Booking } from '../booking.model';
import { Market } from '../../shared/model/resources.model';
import { ExchangeRates } from '../../shared/service/resources/resource.model';
import { AppConstants } from '../../app.constants';
@Injectable({
  providedIn: 'root'
})
export class FlightService {
  private readonly _sellFlightResponse$ = new BehaviorSubject<Booking | null>(null);
  private readonly _selectedDepartDate$ = new BehaviorSubject<Date | null>(null);
  private readonly _selectedReturnDate$ = new BehaviorSubject<Date | null>(null);
  private readonly _selectedOrigin$ = new BehaviorSubject<Market | undefined>(undefined);
  private readonly _selectedDestination$ = new BehaviorSubject<Market | undefined>(undefined);
  private readonly _selectedPassengers$ = new BehaviorSubject<number[]>([]);
  private readonly _selectedDepartingFlightData$ = new BehaviorSubject<FlightSelectedData | null>(null);
  private readonly _selectedReturningFlightData$ = new BehaviorSubject<FlightSelectedData | null>(null);
  private readonly _selectedClassOfService$ = new BehaviorSubject<FlightClassOfServiceEnum>(
    FlightClassOfServiceEnum.EconomyAndScootPlus
  );
  private readonly _selectedCurrency$ = new BehaviorSubject<string | null>('');

  selectedFlightClassOfService = FlightClassOfServiceEnum.EconomyAndScootPlus;
  passengerCriteria!: PassengerCriteria;
  passengers: Passenger[] = [];
  changeFlightData!: ChangeFlightData;
  exchangeRates: ExchangeRates[] = [];
  currencyCode!: string;

  readonly sellFlightResponse$ = this._sellFlightResponse$.asObservable();
  readonly selectedDepartDate$ = this._selectedDepartDate$.asObservable();
  readonly selectedReturnDate$ = this._selectedReturnDate$.asObservable();
  readonly selectedOrigin$ = this._selectedOrigin$.asObservable();
  readonly selectedDestination$ = this._selectedDestination$.asObservable();
  readonly selectedPassengers$ = this._selectedPassengers$.asObservable();
  readonly selectedDepartingFlightData$ = this._selectedDepartingFlightData$.asObservable();
  readonly selectedReturningFlightData$ = this._selectedReturningFlightData$.asObservable();
  readonly selectedClassOfService$ = this._selectedClassOfService$.asObservable();
  readonly selectedCurrencyCode$ = this._selectedCurrency$.asObservable();

  setSelectedDepartDate(date: Date | null) {
    this._selectedDepartDate$.next(date);
  }

  setSelectedReturnDate(date: Date | null) {
    this._selectedReturnDate$.next(date);
  }

  setSelectedOrigin(location: Market | undefined) {
    this._selectedOrigin$.next(location);
  }

  setSelectedDestination(location: Market | undefined) {
    this._selectedDestination$.next(location);
  }

  setSelectedPassengers(passengers: number[]) {
    this._selectedPassengers$.next(passengers);
  }

  setSelectedReturningFlightData(data: FlightSelectedData | null) {
    this._selectedReturningFlightData$.next(data);
  }

  setSelectedDepartingFlightData(data: FlightSelectedData | null) {
    this._selectedDepartingFlightData$.next(data);
  }

  setSelectedClassOfService(data: FlightClassOfServiceEnum) {
    this._selectedClassOfService$.next(data);
  }

  setSelectedCurrency(selectedCurrency: string) {
    this._selectedCurrency$.next(selectedCurrency);
  }

  setChangeFlightData(booking: Booking, isFocBooking: boolean) {
    const isReturnTrip = booking.journeys.length > 1;
    const departFares = booking.journeys[0]?.fares ?? [];
    const changeFlightData = {
      recordLocator: booking.recordLocator,
      isFocBooking: isFocBooking,
      depart: {
        journeyKey: booking.journeys[0]?.journeyKey,
        fares: departFares,
        isScootPlus: departFares.some((p) => p.type === FlightFareTypeEnum.ScootPlus),
        isEconomy: departFares.some((p) => p.type === FlightFareTypeEnum.Economy),
        flightData: {
          fareKey: departFares[0]?.fareKey,
          journeyKey: booking.journeys[0]?.journeyKey,
          price: departFares[0]?.price,
          points: departFares[0]?.points,
          classOfService:
            departFares[0]?.type === FlightClassOfServiceEnum.ScootForSure
              ? FlightClassOfServiceEnum.ScootForSure
              : FlightClassOfServiceEnum.EconomyAndScootPlus,
          fare: departFares[0],
          designator: booking.journeys[0]?.designator
        } as FlightSelectedData
      }
    } as ChangeFlightData;

    if (isReturnTrip) {
      const returnFares = booking.journeys[1]?.fares ?? [];
      changeFlightData.return = {
        journeyKey: booking.journeys[1]?.journeyKey,
        fares: returnFares,
        isScootPlus: returnFares.some((p) => p.type === FlightFareTypeEnum.ScootPlus),
        isEconomy: returnFares.some((p) => p.type === FlightFareTypeEnum.Economy),
        flightData: {
          fareKey: returnFares[0]?.fareKey,
          journeyKey: booking.journeys[1]?.journeyKey,
          price: returnFares[0]?.price,
          points: returnFares[0]?.points,
          classOfService:
            returnFares[0]?.type === FlightClassOfServiceEnum.ScootForSure
              ? FlightClassOfServiceEnum.ScootForSure
              : FlightClassOfServiceEnum.EconomyAndScootPlus,
          fare: returnFares[0],
          designator: booking.journeys[1]?.designator
        } as FlightSelectedData
      };
    }

    this.changeFlightData = changeFlightData;
  }

  isFlightSelectedBelowMinimumHours(selectedFlightType: FlightSelectionTypeEnum | null, selectedflightDate: Date) : boolean {
    let isBelowMinimumHours = false;
    const withSelectedFlight = 
      selectedFlightType === FlightSelectionTypeEnum.Departing && this._selectedReturningFlightData$.value ||
      selectedFlightType === FlightSelectionTypeEnum.Returning && this._selectedDepartingFlightData$.value;
    if (withSelectedFlight) {
      const departingArrivalDate = selectedFlightType === FlightSelectionTypeEnum.Departing
        ? new Date(selectedflightDate)
        : new Date(this._selectedDepartingFlightData$.value?.designator.arrival ?? '');
      const returningDepartureDate = selectedFlightType === FlightSelectionTypeEnum.Returning
        ? new Date(selectedflightDate)
        : new Date(this._selectedReturningFlightData$.value?.designator.departure ?? '');

      const hoursBetweenFlights = (returningDepartureDate.valueOf() - departingArrivalDate.valueOf()) / 36e5;
      isBelowMinimumHours = hoursBetweenFlights < AppConstants.FlightMinimumHours;
    }

    return isBelowMinimumHours;
  }

  resetFlightSelectedData(): void {
    this.setSelectedDepartDate(null);
    this.setSelectedReturnDate(null);
    this.setSelectedOrigin(undefined);
    this.setSelectedDestination(undefined);
    this.setSelectedReturningFlightData(null);
    this.setSelectedDepartingFlightData(null);
    this.passengerCriteria = {} as PassengerCriteria;
    this.passengers = [];
  }
}
