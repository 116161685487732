import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from './header/header.service';
import { PageRoute } from './shared/enums/page-routes.enum';
import { DOCUMENT } from '@angular/common';
import { Subject, delay, take } from 'rxjs';
import { AppConstants } from './app.constants';
import { LoaderService } from './loader/loader.service';
import { SessionExpireService } from './session/session-expire.service';
import { MsalAuthService } from './shared/service/msal-auth/msal-auth.service';
import { SessionService } from './session/session.service';
import { SessionResponse } from './session/session.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly _destroy$ = new Subject<void>();

  withProgressBar = false;
  currentPage = '';
  loading = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private msalAuthService: MsalAuthService,
    private sessionService: SessionService,
    private headerService: HeaderService,
    private loaderService: LoaderService,
    private sessionExpireService: SessionExpireService
  ) {}

  ngOnInit(): void {
    this.checkSessionToken();
    this.listenToLoading();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = this.router.routerState.snapshot.url;
        this.headerService.setCurrentPage(this.currentPage);
        this.withProgressBar =
          this.currentPage.includes(PageRoute.BookFlightSelection) ||
          this.currentPage.includes(PageRoute.BookFlightPayment);
        this.setBackground();
      }
    });
  }

  listenToLoading(): void {
    this.loaderService.loading$.pipe(delay(0)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  private setBackground(): void {
    this.renderer.removeClass(this.document?.body, 'bg-home-desktop');
    if (!this.isMobile && (this.currentPage === PageRoute.BookFlight || this.currentPage === PageRoute.Error)) {
      this.renderer.addClass(this.document?.body, 'bg-home-desktop');
    }
  }

  private checkSessionToken(): void {
    const sessionToken = this.sessionService.getSessionTokenFromSessionStorage();
    const expiresIn = this.sessionService.getExpiresInFromSessionStorage();

    if (sessionToken && sessionToken.trim().length > 0 && expiresIn > 0) {
      const sessionInfo: SessionResponse = {
        accessToken: sessionToken,
        expiresIn: expiresIn
      };

      this.sessionService.setSession(sessionInfo);
      this.refreshSession(expiresIn);
    }
  }

  private refreshSession(expiresIn: number): void {
    this.sessionService
      .refreshSessionToken$()
      .pipe(take(1))
      .subscribe({
        next: (isSuccess) => {
          if (isSuccess) {
            this.sessionExpireService.startTimerCountdown(expiresIn);
          }
          else {
            this.handleSessionTokenError();
          }
        },
        error: () => {
          this.handleSessionTokenError();
        }
      });
  }

  private handleSessionTokenError(): void {
    this.sessionService.clearSessionStorage();
    if (this.msalAuthService.hasSSOLogin) this.msalAuthService.login();
    else window.location.href = PageRoute.Home;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setBackground();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
