import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  BookingFlightTypeEnum,
  CabinOfServiceCode,
  FlightClassOfServiceEnum,
  FlightFareTypeEnum,
  FlightSelectionTypeEnum
} from '../../../../../flight/flight.enum';
import { FlightSelectedData } from '../../../../../flight/flight.model';
import { FlightService } from '../../../../../flight/flight.service';
import { ModalService } from '../../../../../../shared/components/modal/modal.service';
import { FlightFareDetailComponent } from './flight-fare-detail/flight-fare-detail.component';
import { SessionService } from '../../../../../../session/session.service';
import { BookingFare, BookingJourney, BookingLeg, CabinDetail } from '../../../../../../booking/booking.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BookingService } from '../../../../../../booking/booking.service';
import { FlightSelectionErrorHandlerService } from '../../../flight-selection-error-handler.service';
import { ErrorLabelEnum } from '../../../../../../error/error.enum';
@Component({
  selector: 'app-flight-fare',
  templateUrl: './flight-fare.component.html',
  styleUrls: ['./flight-fare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightFareComponent implements OnInit {
  private readonly _destroy$ = new Subject<void>();
  readonly flightFareType = FlightFareTypeEnum;
  readonly flightClassOfServices = FlightClassOfServiceEnum;
  selectedCurrency$!: Observable<string | null>;
  @Input() journey!: BookingJourney;
  @Input() journeyIndex!: number;
  @Input() fare!: BookingFare;
  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() selectedFlightClassOfService!: FlightClassOfServiceEnum | null;
  @Input() totalVoucherPoints!: number;
  @Input() isSelected!: boolean;

  isScootForSureSelected = false;
  isDisabledEconomy = false;
  isDisabledScootPlus = false;
  isValidForScootForsure = true;

  constructor(
    private flightService: FlightService,
    private modalService: ModalService,
    private sessionService: SessionService,
    private bookingService: BookingService,
    private errorHandlerService: FlightSelectionErrorHandlerService,
  ) {}

  ngOnInit(): void {
    const changeFlightData = this.flightService.changeFlightData;
    this.selectedCurrency$ = this.flightService.selectedCurrencyCode$;
    this.isScootForSureSelected = this.flightClassOfServices.ScootForSure.includes(
      this.selectedFlightClassOfService ?? ''
    );
    if (this.bookingService.bookingType === BookingFlightTypeEnum.ChangeFlight) {
      if (this.selectedFlightType === FlightSelectionTypeEnum.Departing) {
        this.isDisabledEconomy = changeFlightData.depart.isScootPlus;
        this.isDisabledScootPlus = this.bookingService.isFocBooking && changeFlightData.depart.isEconomy;
      } else if (this.selectedFlightType === FlightSelectionTypeEnum.Returning && changeFlightData.return) {
        this.isDisabledEconomy = changeFlightData.return.isScootPlus;
        this.isDisabledScootPlus = this.bookingService.isFocBooking && changeFlightData.return.isEconomy;
      }
    }

    this.errorHandlerService.errors$
      .pipe(takeUntil(this._destroy$))
      .subscribe((errors) => {
        this.isValidForScootForsure = !errors.some(
          (error) =>
            error === ErrorLabelEnum.AllowedToSelectTravelBenificiariesForScootforsure ||
            error === ErrorLabelEnum.InsufficientScootforsurePoints
        );
      });
  }

  showDetails(fare: BookingFare) {
    this.modalService.show(FlightFareDetailComponent, fare);
  }

  onSelect() {
    const data = {
      fareKey: this.fare?.fareKey,
      journeyKey: this.journey.journeyKey,
      price: this.fare?.price,
      points: this.fare.points,
      classOfService: this.selectedFlightClassOfService,
      fare: this.fare,
      designator: this.journey.designator
    } as FlightSelectedData;
    const selectedFlightdate =
      this.selectedFlightType === FlightSelectionTypeEnum.Departing
        ? data.designator.arrival
        : data.designator.departure;
    const isFlightBelowMinimumHours = this.flightService.isFlightSelectedBelowMinimumHours(
      this.selectedFlightType,
      selectedFlightdate
    );

    if (isFlightBelowMinimumHours) 
      this.errorHandlerService.emitError(ErrorLabelEnum.FlightBelowMinimumTwoHoursAfterDeparture);
    else {
      this.errorHandlerService.removeError(ErrorLabelEnum.FlightBelowMinimumTwoHoursAfterDeparture);
      this.isSelected = true;
      this.setFlightData(data);
    }
  }

  onEdit() {
    this.setFlightData(null);
  }

  getPriceBasedOnSelectedCurrency(price: number, selectedCurrencyCode: string): number {
    const rate = this.flightService.exchangeRates.find(
      (x) =>
        x.fromCurrencyCode === this.sessionService.getCurrencyCodeFromSessionStorage() &&
        x.toCurrencyCode === selectedCurrencyCode
    )?.exchangeRate;
    return price * (rate ?? 1);
  }

  getSeatsLeft(fareType: string, leg: BookingLeg): number {
    let cabinDetail = {} as CabinDetail | undefined;
    if (fareType === FlightFareTypeEnum.Economy) {
      cabinDetail = leg.cabinDetails.find((x) => x.cabinOfService === CabinOfServiceCode.Economy);
    } else if (fareType === FlightFareTypeEnum.ScootPlus) {
      cabinDetail = leg.cabinDetails.find((x) => x.cabinOfService === CabinOfServiceCode.ScootPlus);
    }
    if (cabinDetail) {
      if (this.fare.type === this.flightFareType.ScootForSure) {
        if (this.fare.points === 0) {
          return 0;
        }
      }
      const seatsLeft =
        cabinDetail.maximumSeatCount - cabinDetail.seatsSold <= 0
          ? 0
          : cabinDetail.maximumSeatCount - cabinDetail.seatsSold;
      return seatsLeft;
    }
    return 0;
  }

  private setFlightData(data: FlightSelectedData | null): void {
    if (this.selectedFlightType === FlightSelectionTypeEnum.Returning) {
      this.flightService.setSelectedReturningFlightData(data);
    } else {
      this.flightService.setSelectedDepartingFlightData(data);
    }
  }

  get currencyCode(): string {
    return this.sessionService.getSelectedCurrencyCode() ?? this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
