import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TripStatusEnum, TabMenuEnum } from './manage-booking.enum';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  takeUntil
} from 'rxjs';
import { ManageBookingService } from './manage-booking.service';
import {
  ManageBookingFlightResponse,
  CloseCancelModalParams,
  GetStaffBookingRequest
} from './manage-booking.model';
import { PaginationBarComponent } from '../pagination-bar/pagination-bar.component';
import { AppConstants } from '../app.constants';
import { SessionService } from '../session/session.service';
import { FlightSearchService } from '../booking/flight/flight-search/flight-search.service';
import { Booking } from '../booking/booking.model';
import { AccountService } from '../account/account.service';

@Component({
  selector: 'app-manage-booking',
  templateUrl: './manage-booking.component.html',
  styleUrls: ['./manage-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageBookingComponent implements OnInit, OnDestroy {
  @ViewChild('pagination') pagination!: PaginationBarComponent;

  private readonly _destroy$ = new Subject<void>();
  private readonly _booking$ = new BehaviorSubject<ManageBookingFlightResponse | null>(null);
  readonly tabMenuEnum = TabMenuEnum;
  readonly tripStatusEnum = TripStatusEnum;

  tabName = TabMenuEnum.Upcoming;
  tripStatus = TripStatusEnum.Upcoming;
  booking$?: Observable<ManageBookingFlightResponse | null>;

  currentPage = 1;
  pageSize = 5;
  totalFlightCount = 0;

  showFlightSearchModal = false;
  flightSearchRecordLocator!: string;

  showCancelJourneyModal = false;
  cancelBooking!: Booking | null;

  constructor(
    private manageBookingService: ManageBookingService,
    private sessionService: SessionService,
    private flightSearchService: FlightSearchService,
    private accountService: AccountService
  ) {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroy$), debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        this.setPagination();
      });
  }

  ngOnInit(): void {
    this.booking$ = this._booking$.asObservable();
    this.sessionService.resetCurrencyToDefaultValues();
    this.getbookings();
  }

  getbookings(): void {
    var userInfo = this.accountService.getUserInfo();
    if (userInfo) {
      const request = {
        staffId: userInfo.employeeId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        type: this.tripStatus,
        page: this.currentPage,
        pageSize: this.pageSize
      } as GetStaffBookingRequest;
      this.manageBookingService
      .getBookings$(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: ManageBookingFlightResponse | null) => {
        if (response) {
          this._booking$.next(response);
          this.totalFlightCount = response.totalFlightCount;
          this.setPagination();
        }
        else {
          this.resetMMBList();
        }
      });
    }
    else {
      this.resetMMBList();
    }
  }

  setActiveTab(tabName: TabMenuEnum, tripStatusEnum: TripStatusEnum) {
    this.tabName = tabName;
    this.tripStatus = tripStatusEnum;
    this.currentPage = 1;

    if (this.pagination) {
      this.pagination.currentPage = 1;
    }

    this.getbookings();
  }

  setPagination(): void {
    if (this.pagination) {
      this.pagination.totalItems = this.totalFlightCount;
      this.pagination.numberOfPages = this.isMobile ? 3 : 5;
      this.pagination.ngOnInit();
    }
  }

  onChangePage(page: number): void {
    this.currentPage = page;
    this.getbookings();
  }

  openFlightSearchModal(recordLocator: string): void { 
    if (recordLocator) {
      this.flightSearchRecordLocator = recordLocator;
      this.flightSearchService.resetForm();
      this.flightSearchService.setFormData(null);
      this.showFlightSearchModal = true;
    }
  }

  closeFlightSearchModal(): void {
    this.flightSearchRecordLocator = '';
    this.flightSearchService.resetForm();
    this.showFlightSearchModal = false;
  }

  openCancelJourneyModal(booking: Booking): void {
    if (booking) {
      this.cancelBooking = booking;
      this.showCancelJourneyModal = true;
    }
  }

  closeCancelJourneyModal(params: CloseCancelModalParams | null): void {
    const isCancelled = params?.bookingIsCancelled;
    const journeyKeys = params?.journeyKeys ?? [];

    if (isCancelled && journeyKeys.length > 0) {
      const journeys = this.cancelBooking?.journeys.filter(p => !journeyKeys.includes(p.journeyKey ?? ''));
      if (journeys && journeys.length > 0) {
        if (journeys.some((x) => x.hoursFromSTD > 0)) {
          this.setActiveTab(TabMenuEnum.Upcoming, TripStatusEnum.Upcoming);
        } else if (journeys.some((x) => x.hoursFromSTD <= 0)) {
          this.setActiveTab(TabMenuEnum.Past, TripStatusEnum.Past);
        }
      }
      else {
        this.setActiveTab(TabMenuEnum.Cancelled, TripStatusEnum.Cancelled);
      }
    }
     
    this.showCancelJourneyModal = false;
    this.cancelBooking = null;
  };

  private resetMMBList() {
    this._booking$.next({ data: [], totalFlightCount: 0 });
    this.totalFlightCount = 0;
    this.setPagination();
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
