<div
  aria-label="Page Content"
  [ngClass]="{
    'bg-mobile': isMobile
  }"
  class="container"
>
  <div class="content">
    <div
      class="tw-flex tw-flex-col tw-relative tw-align-middle tw-bg-white tw-rounded-2xl tw-p-5 tw-gap-5 xs:tw-gap-7.5 xs:tw-p-7.5"
    >
      <div class="tw-text-center tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center xs:tw-p-[7%]">
        <div class="tw-text-stone-900 tw-text-4xl tw-font-bold tw-leading-10">
          <h2
            aria-label="Error message"
            class="tw-text-[22px] xs:tw-text-[32px]"
            [innerHTML]="errorMessage | translate"
          ></h2>
          <button
            *ngIf="showButton"
            type="button"
            (click)="clickButton()"
            aria-label="Error button"
            class="tw-btn tw-btn-primary tw-w-full tw-mt-[20px] xs:tw-w-36"
          >
            {{ buttonLabel | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
