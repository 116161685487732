import { Component, Input } from '@angular/core';
import { FlightSelectionTypeEnum } from '../../flight.enum';

@Component({
  selector: 'app-flight-selection-sfs-notification',
  templateUrl: './flight-selection-sfs-notification.component.html',
  styleUrls: ['./flight-selection-sfs-notification.component.scss']
})
export class FlightSelectionSfsNotificationComponent {

  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() maxAvailableSeats!: number;
  @Input() isVisible: boolean = false;
  
  onClose() {
    this.isVisible = false;
  }
}
