
export const FlightConstants = {
  Destination: 'Destination',
  Passengers: 'Passengers',
  Origin: 'Origin',
  Dropdown: 'Dropdown',
  From: 'From',
  To: 'To',
};

export const PassengerErrorConstants = {
  NameError: 'label.book-flight-search.passenger.error.passenger-error-name'
}