import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Market } from '../../../../shared/model/resources.model';
import { FlightService } from '../../flight.service';
import { BookingFlightTypeEnum, FlightSelectionTypeEnum } from '../../flight.enum';
import { BookingService } from '../../../../booking/booking.service';

@Component({
  selector: 'app-flight-selection-header',
  templateUrl: './flight-selection-header.component.html',
  styleUrls: ['./flight-selection-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectionHeaderComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  readonly bookingFlightTypeEnum = BookingFlightTypeEnum;

  @Input() isReturn!: boolean | false;
  @Output() onModify = new EventEmitter();

  flightSelectionTypes = FlightSelectionTypeEnum;
  selectedOrigin$!: Observable<Market | undefined>;
  selectedDestination$!: Observable<Market | undefined>;
  showFlightSearchModal = false;

  constructor(private flightService: FlightService, private bookingService: BookingService) {}

  ngOnInit(): void {
    this.selectedOrigin$ = this.flightService.selectedOrigin$;
    this.selectedDestination$ = this.flightService.selectedDestination$;
  }
  
  onClickModify(){
    this.onModify.emit()                           
  }

  get bookingFlightType(): BookingFlightTypeEnum | null {
    return this.bookingService.bookingType;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
