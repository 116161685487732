import { Injectable, NgZone } from '@angular/core';
import { SessionService } from './session.service';
import { environment } from '../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { ModalService } from '../shared/components/modal/modal.service';
import { SessionExpiringComponent } from './session-expiring/session-expiring.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';

@Injectable({
  providedIn: 'root'
})
export class SessionExpireService {
  private _remainingTimeBeforeExpiryInSecond$ = new ReplaySubject<number>(1);
  private interval: any;

  remainingTimeBeforeExpiryInSeconds = 0;
  startExpireTimeoutInSeconds = 0;
  warningTimeoutInSeconds = environment.session.warningTimeoutInSeconds;
  expiresInGracePeriodInSeconds = environment.session.expiresInGracePeriodInSeconds;
  showSessionExpired = environment.session.sessionExpired;
  expirationDateTime: Date | null = null;

  constructor(private sessionService: SessionService, private modalService: ModalService, private zone: NgZone) {
    this.warningTimeoutInSeconds = environment.session.warningTimeoutInSeconds;
  }

  startTimerCountdown(startExpireIn: number): void {
    this.startExpireTimeoutInSeconds = startExpireIn;
    const timeBeforeGracePeriodSubtraction = startExpireIn - this.expiresInGracePeriodInSeconds;
    this.remainingTimeBeforeExpiryInSeconds =
      timeBeforeGracePeriodSubtraction > 0 ? timeBeforeGracePeriodSubtraction : 0;
    this.expirationDateTime = new Date();
    this.expirationDateTime.setSeconds(this.expirationDateTime.getSeconds() + this.remainingTimeBeforeExpiryInSeconds);
    this.startTimer();
  }

  reset(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      this.startTimerCountdown(this.startExpireTimeoutInSeconds);
    }
  }

  stop(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  showSessionExpiredPopup(): void {
    this.sessionService.clearSessionStorage();
    this.expirationDateTime = null;
    this.stop();

    if (this.showSessionExpired) {
      this.zone.run(() => {
        this.modalService.show(SessionExpiredComponent);
      });
    }
  }

  showSessionExpiringPopup(): void {
    if (this.showSessionExpired) {
      this.zone.run(() => {
        this._remainingTimeBeforeExpiryInSecond$.next(this.remainingTimeBeforeExpiryInSeconds);
        this.modalService.show(SessionExpiringComponent);
      });
    }
  }

  private startTimer(): void {
    clearInterval(this.interval);
    this.interval = null;

    this.zone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        if (this.isTokenExpired) {
          this.showSessionExpiredPopup();
          return;
        }

        if (this.remainingTimeBeforeExpiryInSeconds > 0) {
          this.remainingTimeBeforeExpiryInSeconds--;
          if (this.remainingTimeBeforeExpiryInSeconds <= this.warningTimeoutInSeconds) 
            this.showSessionExpiringPopup();
        }
      }, 1000);
    });
  }

  get isTokenExpired(): boolean {
    return (this.expirationDateTime && new Date() > this.expirationDateTime) ?? false;
  }

  get remainingTimeBeforeExpiryInSecond$(): Observable<number> {
    return this._remainingTimeBeforeExpiryInSecond$;
  }
}
