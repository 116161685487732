import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) { }

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
        this.clickOutside.emit(event);
      }
  }
}
