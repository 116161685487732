import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '../../../../../../../shared/components/modal/modal.service';
import { FlightFareTypeEnum } from '../../../../../../flight/flight.enum';

@Component({
  selector: 'app-flight-fare-detail',
  templateUrl: './flight-fare-detail.component.html',
  styleUrls: ['./flight-fare-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightFareDetailComponent {
  readonly flightFareType = FlightFareTypeEnum;

  @Input() data!: any;

  details = [
    {
      ariaLabel: 'ticket type',
      icon: '/assets/icons/svg/icon-ticket-type.svg',
      title: 'label.book-flight.flight-details.ticket-type',
      economy: 'label.book-flight.flight-details.ticket-type.economy',
      scootPlus: 'label.book-flight.flight-details.ticket-type.scoot-plus',
      scootForSure: 'label.book-flight.flight-details.ticket-type.scootforsure'
    },
    {
      ariaLabel: 'seat type',
      icon: '/assets/icons/svg/icon-seat.svg',
      title: 'label.book-flight.flight-details.seat-type',
      economy: 'label.book-flight.flight-details.seat-type.economy',
      scootPlus: 'label.book-flight.flight-details.seat-type.scoot-plus',
      scootForSure: 'label.book-flight.flight-details.seat-type.scootforsure'
    },
    {
      ariaLabel: 'cabin baggage',
      icon: '/assets/icons/svg/icon-cabin-baggage.svg',
      title: 'label.book-flight.flight-details.cabin-baggage',
      economy: 'label.book-flight.flight-details.cabin-baggage.economy',
      scootPlus: 'label.book-flight.flight-details.cabin-baggage.scoot-plus',
      scootForSure: 'label.book-flight.flight-details.cabin-baggage.scootforsure'
    },
    {
      ariaLabel: 'check-in baggage',
      icon: '/assets/icons/svg/icon-check-in-baggage.svg',
      title: 'label.book-flight.flight-details.check-in-baggage',
      economy: 'label.book-flight.flight-details.check-in-baggage.economy',
      scootPlus: 'label.book-flight.flight-details.check-in-baggage.scoot-plus',
      scootForSure: 'label.book-flight.flight-details.check-in-baggage.scootforsure'
    },
    {
      ariaLabel: 'meal',
      icon: '/assets/icons/svg/icon-meal.svg',
      title: 'label.book-flight.flight-details.meal',
      economy: '',
      scootPlus: 'label.book-flight.flight-details.meal.scoot-plus',
      scootForSure: ''
    },
    {
      ariaLabel: 'priority',
      icon: '/assets/icons/svg/icon-priority.svg',
      title: 'label.book-flight.flight-details.priority',
      economy: '',
      scootPlus: 'label.book-flight.flight-details.priority.yes',
      scootForSure: ''
    },
    {
      ariaLabel: 'wifi',
      icon: '/assets/icons/svg/icon-wifi.svg',
      title: 'label.book-flight.flight-details.wifi',
      economy: '',
      scootPlus: 'label.book-flight.flight-details.wifi.where-available',
      scootForSure: ''
    },
    {
      ariaLabel: 'charging outlet',
      icon: '/assets/icons/svg/icon-charging-outlet.svg',
      title: 'label.book-flight.flight-details.charging',
      economy: '',
      scootPlus: 'label.book-flight.flight-details.charging.yes',
      scootForSure: ''
    }
  ];

  constructor(private modalService: ModalService) {}

  onCloseModal() {
    this.modalService.hide();
  }

  clickPopupBackgroundEvent(): void {}
}
