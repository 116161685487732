import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PageRoute } from '../../../shared/enums/page-routes.enum';
import { BookingFlightTypeEnum, FlightFareTypeEnum, PassengerTypeEnum } from '../../flight/flight.enum';
import { Subject, take, takeUntil } from 'rxjs';
import { BookingService } from '../../booking.service';
import { NotificationService } from '../../../shared/components/notification/notification.service';
import { AppConstants } from '../../../app.constants';
import { PaymentService } from '../../payment/payment.service';
import { Booking, BookingDetail, BookingResponse } from '../../booking.model';
import { SessionService } from '../../../session/session.service';
import { NotificationData } from '../../../shared/components/notification/notification.model';
import { ReviewSummaryModuleService } from '@it-application-hub/bookings';
import { HeaderService } from '../../../header/header.service';
import { AddOnsModuleService } from '@it-application-hub/add-ons';
import { FlightService } from '../flight.service';
import { AccountService } from '../../../account/account.service';
import { Router } from '@angular/router';
import { ActivitySettings } from '@it-application-hub/add-ons/lib/configuration/add-ons-configuration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-flight-payment',
  templateUrl: './flight-payment.component.html',
  styleUrls: ['./flight-payment.component.scss']
})
export class FlightPaymentComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private readonly ofwCityCodes = AppConstants.OfwCityCodes;
  readonly bookingFlightTypeEnum = BookingFlightTypeEnum;

  isMakePaymentDisabled: any | undefined;
  bookingDetail!: BookingDetail;
  booking!: Booking | undefined;
  pointsBalanceDue = 0;
  isSubmitPayment = false;
  withFOC = false;
  refreshBooking = true;
  isLoaded = false;

  constructor(
    private router: Router,
    private bookingService: BookingService,
    private notificationService: NotificationService,
    private paymentService: PaymentService,
    private addOnsModuleService: AddOnsModuleService,
    private sessionService: SessionService,
    private bookingModuleService: ReviewSummaryModuleService,
    private headerService: HeaderService,
    private flightService: FlightService,
    private accountService: AccountService,
    private el: ElementRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.bookingDetail = this.bookingService.bookingDetail;
    this.withFOC =
      (this.bookingDetail.userContactInformation?.isUnionMember ?? false) &&
      !this.bookingDetail.isFlightScootForSure &&
      !this.bookingDetail.isFlightWithScootPlus &&
      this.bookingFlightType !== BookingFlightTypeEnum.ManageAddOns &&
      this.bookingFlightType !== BookingFlightTypeEnum.ChangeFlight;

    //Currency selection
    this.flightService.selectedCurrencyCode$.pipe(takeUntil(this._destroy$)).subscribe((currencyCode) => {
      if (currencyCode && this.isLoaded) {
        this.configureAddOnsMFE(this.currencyCode);
        this.configureBookingMFE(this.currencyCode);
      }
    });

    //Add ons
    this.addOnsModuleService.notify$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.paymentService.reloadPaymentPage$.next(true);
    });

    //Payment
    this.paymentService.paymentOnProcess$.pipe(takeUntil(this._destroy$)).subscribe((success) => {
      this.isSubmitPayment = success;

      if (!success) {
        setTimeout(() => {
          let elementWithError: any = null;
          let fieldElements = this.el.nativeElement.querySelectorAll('.hasError, .ng-invalid');

          if (fieldElements.length > 0) {
            fieldElements.forEach((fieldElement: HTMLElement) => {
              const ariaLabel = fieldElement.getAttribute('aria-label');
              if (ariaLabel && !elementWithError) {
                elementWithError = fieldElement;
              }
            });
          }

          if (!elementWithError) elementWithError = this.el.nativeElement.querySelector('.payment-error');

          if (elementWithError) {
            elementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elementWithError.focus();
          }
        }, 500);
      }
    });

    this.paymentService.reloadPaymentPage$.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (res) {
        this.refreshBooking = !this.refreshBooking;
        this.retrieveBooking();
      }
    });

    //Initialze data
    this.headerService.setCurrencyCode();
    this.translateService.use(AppConstants.DefaultCultureCode);

    setTimeout(() => {
      this.configureAddOnsMFE(this.currencyCode);
      this.configureBookingMFE(this.currencyCode);
      this.retrieveBooking();
    });
  }

  onBack(): void {
    this.bookingService
      .clearBooking$()
      .pipe(takeUntil(this._destroy$))
      .subscribe((isSuccess) => {
        if (isSuccess) {
          if (this.bookingFlightType === BookingFlightTypeEnum.ChangeFlight) {
            this.resetBookingForChangeFlight();
          } else {
            this.router.navigateByUrl(PageRoute.BookFlightSelection);
          }
        }
      });
  }

  makePayment(): void {
    var hasStaff = this.bookingDetail?.passengers?.some((passenger) => !passenger.relationship);
    if (hasStaff) {
      this.staffTravelReminder();
    } else {
      this.proceedToPayment();
    }
  }

  private proceedToPayment(): void {
    if ((this.booking?.breakDown?.balanceDue ?? 0) < 0) {
      this.notifyPaymentWithNegativeBalanceDue();
    } else {
      if (this.bookingDetail.isFlightScootForSure) {
        this.notifyNoChangesAllowedForScootForsure();
      } else {
        var hasScootPlusWithNoMealsSelected = this.booking?.journeys
          .flatMap((x) => x.journeyAddOns)
          .filter((x) => x?.type === FlightFareTypeEnum.ScootPlus)
          .flatMap((x) => x?.meals)
          .some((x) => !x?.code);
        if (hasScootPlusWithNoMealsSelected) {
          this.notifyNoSelectedComplimentaryMealsForScootPlus();
        } else {
          this.submitPayment();
        }
      }
    }
  }

  private submitPayment(): void {
    var userInfo = this.accountService.getUserInfo();
    if (userInfo) {
      const focPassengers = this.bookingDetail.passengers;
      const isFocBooking = this.bookingService.isFocBooking;
      this.bookingService.setBookingRequest(userInfo, focPassengers, isFocBooking);
      this.paymentService.submitPayment();
    }
  }

  private staffTravelReminder(): void {
    const self = this;
    const notification: NotificationData = {
      imageUrl: '',
      title: 'notification.title.annual-leave-reminder',
      text: 'notification.text.annual-leave-reminder',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'notification.button.cancel',
      confirmButtonText: 'notification.button.acknowledge',
      textAlignClass: 'tw-text-left',
      callBack: function (isConfirm: boolean) {
        if (isConfirm) {
          self.proceedToPayment();
        }
      }
    };
    this.notificationService.show(notification);
  }

  private notifyPaymentWithNegativeBalanceDue(): void {
    const notification: NotificationData = {
      title: 'notification.title.payment-with-negative-balance',
      text: 'notification.text-make-payment-with-negative-outstanding-balance.not.allowed',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'notification.button.goBack'
    };
    this.notificationService.show(notification);
  }

  private notifyNoChangesAllowedForScootForsure(): void {
    const self = this;
    const messageText =
      this.paymentService.voucherTotalRedeemedPoints <= this.pointsBalanceDue
        ? 'notification.text.thanksPoint'
        : 'notification.text.thanksPoint-exceed';

    const notification: NotificationData = {
      title: 'notification.title.noChangesAllowed',
      text: messageText,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'notification.button.cancel',
      confirmButtonText: 'notification.button.confirm',
      callBack: function (isConfirm: boolean) {
        if (isConfirm) {
          self.paymentService.submitPayment();
        }
      }
    };
    this.notificationService.show(notification);
  }

  private notifyNoSelectedComplimentaryMealsForScootPlus(): void {
    const self = this;
    const notification: NotificationData = {
      imageUrl: '/assets/icons/svg/group.svg',
      title: 'notification.title.notSelectedComplimentaryMeals',
      text: 'notification.text.notSelectedComplimentaryMeals',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'notification.button.goBack',
      confirmButtonText: 'notification.button.proceedPayment',
      callBack: function (isConfirm: boolean) {
        if (isConfirm) {
          self.submitPayment();
        }
      }
    };
    this.notificationService.show(notification);
  }

  private resetBookingForChangeFlight(): void {
    this.bookingService
      .searchBooking$({
        recordLocator: this.booking?.recordLocator ?? ''
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: BookingResponse) => {
        if (data.isSuccess) {
          this.router.navigateByUrl(PageRoute.BookFlightSelection);
        }
      });
  }

  private configureAddOnsMFE(currencyCode: string): void {
    const configuration = {
      passengerFilter: [],
      journeyFilter: [],
      currency: currencyCode,
      showSummary: false,
      showApplyToAllPassengerSnoozeKit: false,
      showApplyBaggageToAllPassenger: true,
      showExpandAddOnsSummary: false,
      showMealCard: true,
      showSeatCard: false,
      showBaggageCard: true,
      showFlexCard: false,
      showSnoozeKitCard: false,
      showBoardMeFirstCard: false,
      showWifiCard: false,
      showPokemonCard: false,
      showInsurance: false,
      showInsuranceButton: false,
      showAdditionalMealPurchase: false,
      eventCategory: AppConstants.EventCategory,
      isShowSeatCardFooterRandomAssignSeatContent: false,
      originMarkets: [],
      showCFARAddOn: false,
      showActivityLog: {
        userActivityKey: 'add-ons-user-activity-comment',
        ancillaryListKey: 'add-ons-activity-comment'
      } as ActivitySettings,
      showScootplusAlcoholTopUp: false,
      showNewPopUpSequence: false,
      showIncreaseDecreaseOptionMeals: false
    };
    this.addOnsModuleService.initialize(configuration);
  }

  private configureBookingMFE(currencyCode: string): void {
    const configuration = {
      cultureCode: AppConstants.DefaultCultureCode,
      eventCategory: AppConstants.EventCategory,
      isHidingTotalPaidDueSection: false,
      addOnPath: '',
      isShowNoBaggageReminder: false,
      showAmountSection: false,
      selectedCurrencyCode: {
        code: currencyCode
      }
    };
    this.bookingModuleService.initialize(configuration);
  }

  private validateMakePaymentStatus(): void {
    if (this.bookingDetail.isFlightScootForSure && this.bookingFlightType === BookingFlightTypeEnum.NewBooking) {
      this.isMakePaymentDisabled = true;
      const payments = this.booking?.payments.filter((x) => x.code === AppConstants.VoucherPaymentCode);
      if (payments?.length) {
        const points = payments?.reduce((totalPoints, amount) => (totalPoints += amount.amounts.amount), 0) ?? 0;
        this.isMakePaymentDisabled = points < (this.booking?.breakDown.journeyTotals.totalAmount ?? 0);
      }
    } else {
      this.isMakePaymentDisabled = false;
    }
  }

  private retrieveBooking(): void {
    this.pointsBalanceDue = 0;
    this.bookingService
      .retrieveBooking$()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        if (!response?.booking) {
          this.router.navigateByUrl(PageRoute.Home);
        } else {
          this.booking = response.booking;
          this.pointsBalanceDue = this.booking.breakDown?.pointsBalanceDue ?? 0;

          const isOFW =
            this.ofwCityCodes.includes(this.booking.origin) ||
            (this.booking.journeys.length > 1 ? this.ofwCityCodes.includes(this.booking.destination) : false);
          if (isOFW) {
            this.paymentService.setOFWPassengersData(
              this.booking.passengers?.filter((x) => x.passengerType !== PassengerTypeEnum.INFANT) ?? []
            );
          }

          if (this.withFOC) {
            this.paymentService.setFOCPassengersData(
              this.bookingDetail.passengers.filter((x) => x.passengerTypeCode !== PassengerTypeEnum.INFANT) ?? [],
              this.bookingService.isFocBooking,
              this.booking.journeys.length > 1 ? 2 : 1
            );
          }

          this.paymentService.setVouchersData(this.booking.payments, this.bookingDetail.vouchers);
          this.validateMakePaymentStatus();
          this.isLoaded = true;
        }
      });
  }

  get bookingFlightType(): BookingFlightTypeEnum | null {
    return this.bookingService.bookingType;
  }

  get currencyCode(): string {
    return this.bookingDetail.isFlightScootForSure
      ? AppConstants.DefaultCurrencyCode
      : this.sessionService.getSelectedCurrencyCode()
      ? this.sessionService.getSelectedCurrencyCode()
      : this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
