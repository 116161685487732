import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BookingJourney } from '../../../../../../booking/booking.model';
import { ModalService } from '../../../../../../shared/components/modal/modal.service';
import { FlightDetailViewComponent } from './flight-detail-view/flight-detail-view.component';
import { FlightSelectionTypeEnum } from '../../../../../../booking/flight/flight.enum';
import { AppConstants } from '../../../../../../app.constants';

@Component({
  selector: 'app-flight-detail',
  templateUrl: './flight-detail.component.html',
  styleUrls: ['./flight-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightDetailComponent implements OnInit {
  @Input() journey!: BookingJourney;
  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;

  transitTimeString = '';
  transitCity = '';
  detailType!: string;
  stops!: number;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.stops = this.journey.stops;
    this.detailType = this.selectedFlightType ? `${this.selectedFlightType} - ` : '';
    this.calculate1StopTransitTime();
  }

  calculate1StopTransitTime() {
    if (this.journey) {
      this.transitCity = this.journey.segments[0]?.legs[0]?.designator?.destination ?? '';
      this.transitTimeString = this.journey.designator.duration;
    }
  }

  viewDetails() {
    this.modalService.show(FlightDetailViewComponent, {
      journey: this.journey,
      transitTimeString: this.transitTimeString,
      transitCity: this.transitCity
    });
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }
}
