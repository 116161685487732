<div
  #dropdownElement
  class="dropdown-container  xs:tw-h-8.75"
  [class.rounded]="config.isRounded"
  attr.aria-label="Dropdown container for {{ config.componentIdentifier }}"
>
  <!--Input-->
  <div
    class="dropdown-value-container tw-relative"
    [class.hasError]="hasError"
    [class.focus]="showDropdownOptions"
    (click)="showDropdownOptions = !isDisabled"
  >
    <input
      autocomplete="vcc"
      #dropdownInput
      class="dropdown-input tw-mt-0.75"
      [ngClass]="{ 'tw-pl-3': config.prefix, 'tw-indent-9': config.hasPrefixIcon }"
      [class.hasError]="hasError"
      attr.aria-label="Dropdown input for {{ config.componentIdentifier }}"
      type="text"
      (keydown.Tab)="onFocusedOut()"
      [placeholder]="placeholder | translate"
      [value]="selectedValues"
      [readOnly]="true"
      [hidden]="isMobile"
      [disabled]="isDisabled || showLoader"
      (focus)="onFocusIn()"
    />
    <span
      class="tw-absolute tw-left-0 tw-top-[10px] tw-text-lg tw-z-30"
      [ngClass]="config.control.value ? 'tw-text-black-100' : 'tw-text-black-40'"
      *ngIf="config.prefix"
      >{{ config.prefix }}</span
    >
    <app-spinner
      *ngIf="showLoader"
      class="tw-absolute tw-z-40 tw-right-[10px] tw-bottom-[7px]"
    ></app-spinner>
  </div>
  <!--Options-->
  <div
    [hidden]="!showDropdownOptions && !isMobile"
    [class.mobile]="isMobile"
    class="dropdown-option-container tw-text-4.5 tw-leading-7"
    attr.aria-label="Dropdown options container for {{ config.componentIdentifier }}"
  >
  <div class="dropdown-option-container-div" [class.mobile]="isMobile">
    <div
        *ngFor="let option of options"
        class="dropdown-option tw-border tw-bottom-1 tw-flex tw-px-3 tw-py-1.5 tw-whitespace-nowrap tw-items-start tw-self-stretch tw-justify-between"
        (click)="onOptionClicked(option)"
        [class.selected]="option.selected"
        [class.disabled]="option.isDisabled"
        attr.aria-label="Dropdown option for {{ config.componentIdentifier }}"
      >
        <div class="tw-flex-col tw-justify-start">
          <div class="tw-text-lg tw-leading-normal tw-text-wrap">{{ config.prefix }}{{ option.value }}</div>
          <div
            class="tw-max-w-xs"
            id="Error message for {{ option.value }}"
            aria-label="Error message for option"
            *ngIf="option.hasError"
          >
            <p class="tw-text-xs tw-text-red-600 tw-break-words tw-whitespace-normal">
              {{ option.errorMessage | translate }}
            </p>
          </div>
        </div>
        <div class="tw-h-[25px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-flex">
          <div class="tw-flex tw-items-center tw-mt-[10px] tw-mb-4">
            <img
              src="{{ option.imageUrl }}"
              *ngIf="option.imageUrl"
              class="tw-bg-cyan-500 tw-rounded-2xl tw-left-1 tw-mr-[10px] tw-mt-[5px]"
              attr.aria-label="Image container for {{ config.componentIdentifier }}"
            />
            <input
              id="Checkbox id for {{ option.value }}"
              (change)="(option.selected)"
              [checked]="option.selected"
              [disabled]="option.hasError || option.isDisabled"
              attr.aria-label="Checkbox for {{ config.componentIdentifier }}"
              type="checkbox"
              value=""
              class="tw-checkbox tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 focus:tw-ring-blue-500 dark:tw-focus:ring-blue-600 dark:tw-ring-offset-gray-800 tw-focus:ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600 tw-border-black"
            />
          </div>
        </div>
      </div>
      <div
        *ngIf="options && options.length === 0"
        class="dropdown-option tw-flex tw-items-center tw-px-5 tw-py-2.5 tw-text-black-40"
        attr.aria-label="Dropdown option for {{ config.componentIdentifier }}"
      >
        {{ 'label.' + config.componentIdentifier + '.not-found' | translate }}
      </div>
    </div>
    <!--Footer-->
    <div
      aria-label="Dropdown Footer section"
      *ngIf="!isMobile"
      class="tw-py-[10px] tw-px-[15px] tw-bg-zinc-100 tw-border-black-40 tw-border-t-[1px] tw-border-solid tw-inset-x-0 xs:tw-static xs:tw-bottom-auto xs:tw-inset-x-auto"
    >
      <div class="tw-flex tw-flex-col tw-gap-5 tw-justify-end">
        <div class="tw-flex tw-mt-auto xs:tw-self-end tw-gap-3.25">
          <button
            aria-label="Done button"
            (click)="onDoneClicked()"
            class="tw-btn tw-btn-primary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
          >
            {{ 'label.button.done.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
