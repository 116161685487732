<div
  aria-label="Mobile Calendar modal"
  aria-modal="true"
  role="dialog"
  [hidden]="!isCalendarModalVisible"
  class="modal-window-container"
>
  <div class="modal-container" aria-label="Modal container">
    <div class="modal-header" aria-label="Modal header">
      <div class="tw-h-[4rem]">
        <div class="modal-header-container tw-bg-yellow-100">
          <div
            aria-label="Mobile Calendar Modal title"
            class="tw-text-center tw-items-center tw-justify-center tw-font-bold"
          >
            {{ 'label.book-flight-search.calendar.title' | translate }}
          </div>
          <div class="modal-close">
            <img
              aria-label="Mobile Calendar Modal Close icon"
              class="tw-absolute tw-right-5 tw-top-4"
              (click)="onCloseModal()"
              src="/assets/icons/svg/exit.svg"
              alt="exit"
            />
          </div>
        </div>
      </div>
      <div class="tw-h-[auto] tw-bg-white">
        <form [formGroup]="modalFormGroup" aria-label="Calendar Form Group">
          <div>
            <!--Location-->
            <div
              aria-label="Location value"
              class="tw-h-[40px] tw-p-[10px] tw-border-b tw-border-b-black-40 tw-text-stone-900 tw-text-sm tw-font-medium"
            >
              <span *ngIf="_origin || _destination">
                {{ _origin }} > {{ _destination }}
              </span>
            </div>
            <!--Depart/Return Date-->
            <div [ngClass]="_isReturn ? 'tw-flex tw-flex-row' : ''">
              <!--Depart Date-->
              <div class="detail-section-item">
                <div class="tw-border-r tw-border-r-black-40">
                  <label aria-label="Depart date label" class="dateInputLabel">
                    {{ 'label.book-flight-search.depart-date.label' | translate }}
                  </label>
                  <input
                    aria-label="Depart date field"
                    readonly
                    type="text"
                    #departDate
                    id="departDate"
                    name="departDate"
                    class="tw-text-input dateInput"
                    formControlName="departDate"
                    [value]="departureDateControl.value"
                  />
                </div>
              </div>
              <!--Return Date-->
              <div
                class="detail-section-item"
                *ngIf="_isReturn"
                [ngClass]="_isReturn ? 'tw-flex tw-flex-col' : 'tw-flex tw-flex-row'"
              >
                <label aria-label="Return date label" class="dateInputLabel">
                  {{ 'label.book-flight-search.return-date.label' | translate }}
                </label>
                <input
                  aria-label="Return date field"
                  readonly
                  type="text"
                  #returnDate
                  *ngIf="modalFormGroup.controls['returnDate']"
                  id="returnDate"
                  name="returnDate"
                  class="tw-text-input dateInput"
                  formControlName="returnDate"
                  [value]="returnDateControl.value"
                />
              </div>
            </div>
          </div>
          <!--Weekdays-->
          <div *ngIf="showDatepicker">
            <div
              aria-label="Weekdays section"
              class="tw-w-full tw-pr-[15px] tw-pl-[15px] tw-bg-neutral-200 tw-justify-start tw-items-start tw-inline-flex"
            >
              <div
                *ngFor="let week of weekDays"
                class="tw-grow tw-shrink tw-basis-0 tw-px-1 tw-py-1 tw-rounded-sm tw-flex-col tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex"
              >
                <div
                  [attr.aria-label]="'Weekday [' + week + ']'"
                  class="tw-text-center tw-text-stone-900 tw-text-xs tw-font-medium"
                >
                  {{ week }}
                </div>
              </div>
            </div>
          </div>
          <!--Calendar-->
        </form>
      </div>
    </div>
    <div class="body" aria-label="Modal body">
        <div class="modal-body">
          <app-flight-search-calendar
            aria-label="Mobile Calendar section"
            class="tw-pb-[30px]"
            #searchCalendar
            [showDatepicker]="isCalendarModalVisible"
            [terminationDate]="terminationDate"
            [departureDateControl]="departureDateControl"
            [returnDateControl]="returnDateControl"
            (onCloseCalendar)="onCloseModal()"
            (isDisabledButton)="isDisabledButton($event)"
          >
          </app-flight-search-calendar>
      </div>
    </div>
    <div class="modal-footer" aria-label="Modal footer">
      <button
        aria-label="Calendar Reset button"
        (click)="onClickReset()"
        [disabled]="disabledButton"
        class="tw-btn tw-btn-secondary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
      >
        {{ 'label.button.reset.label' | translate }}
      </button>
      <button
        aria-label="Calendar Done button"
        (click)="onClickDone()"
        [disabled]="disabledButton"
        class="tw-btn tw-btn-primary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
      >
        {{ 'label.button.done.label' | translate }}
      </button>
    </div>
  </div>
</div>