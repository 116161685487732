<div class="tw-relative" aria-label="Loading screen">
  <div class="tw-fixed tw-inset-0 tw-z-[200] tw-transition-opacity"></div>
  <div aria-label="Loading modal" class="tw-fixed tw-inset-0 tw-z-modal tw-bg-white/80 tw-overflow-y-auto">
    <div class="tw-flex tw-flex-col tw-z-modal tw-justify-center tw-text-center tw-h-full tw-items-center tw-p-0">
      <h1 aria-label="Loading header" class="tw-text-stone-900 xs:tw-text-4xl tw-text-2xl tw-font-bold tw-leading-10">
        {{ 'label.loading.header.label' | translate }}
      </h1>
      <img aria-label="Loading gif" src="assets/images/loading.png" alt="api-loading" class="rotating-image" />
    </div>
  </div>
</div>

