import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import {
  CurrencyResponse,
  ExchangeRateResponse,
  Market,
  MarketResponse,
  SettingResponse,
  StationResponse
} from './resource.model';
import { AppConstants } from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private _stations: BehaviorSubject<StationResponse[]> = new BehaviorSubject<StationResponse[]>([]);
  private _externalRates: BehaviorSubject<ExchangeRateResponse[]> = new BehaviorSubject<ExchangeRateResponse[]>([]);
  private _settings: BehaviorSubject<SettingResponse | undefined> = new BehaviorSubject<SettingResponse | undefined>(
    undefined
  );
  private _marketOrigins: BehaviorSubject<Market[]> = new BehaviorSubject<Market[]>([]);

  constructor(private httpClient: HttpClient) {}

  retrieveResources() {
    if (this._stations.value.length == 0) {
      this.retrieveStations()
        .pipe(tap((value) => this._stations.next(value)))
        .subscribe();
    }

    if (this._externalRates.value?.length == 0) {
      this.retrieveExternalRates()
        .pipe(tap((value) => this._externalRates.next(value)))
        .subscribe();
    }

    if (this._marketOrigins.value?.length == 0) {
      this.retrieveMarketOrigins()
        .pipe(tap((value) => this._marketOrigins.next(value)))
        .subscribe();
    }

    if (!this._settings.value) {
      this.retrieveSettings()
        .pipe(tap((value) => this._settings.next(value)))
        .subscribe();
    }
  }

  get stations$(): BehaviorSubject<StationResponse[]> {
    return this._stations;
  }

  get externalRates$(): BehaviorSubject<ExchangeRateResponse[]> {
    return this._externalRates;
  }

  get marketOrigins$(): BehaviorSubject<Market[]> {
    return this._marketOrigins;
  }

  get settings$(): BehaviorSubject<SettingResponse | undefined> {
    return this._settings;
  }

  private retrieveSettings() {
    return this.httpClient.get<SettingResponse>('/resources/settings');
  }

  private retrieveStations() {
    return this.httpClient.get<StationResponse[]>('/resources/stations');
  }

  private retrieveExternalRates() {
    return this.httpClient.get<ExchangeRateResponse[]>('/resources/externalrates');
  }

  private retrieveMarketOrigins() {
    return this.httpClient.get<MarketResponse>('/resources/markets/origins').pipe(
      map((data) => {
        return data?.markets ?? [];
      })
    );
  }

  getMarketDestination$(OriginStationCode: string): Observable<Market[]> {
    return this.httpClient
      .get<MarketResponse>('/resources/markets/destinations', {
        params: new HttpParams().set('originStationCode', OriginStationCode)
      })
      .pipe(
        map((data) => {
          return data.markets;
        })
      );
  }

  getCurrencies$(): Observable<CurrencyResponse[]> {
    return this.httpClient.get<CurrencyResponse[]>('/resources/currencies').pipe(
      tap((data) => {
        const sgdIndex = data.findIndex((x) => x.currencyCode === AppConstants.DefaultCurrencyCode);
        if (sgdIndex !== -1) {
          data.unshift(data.splice(sgdIndex, 1)[0]);
        }
      })
    );
  }
}
