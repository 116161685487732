<div
  aria-label="Session timeout background"
  class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
></div>
<div aria-label="Session expired container" class="tw-relative tw-w-full tw-max-h-full">
  <div class="tw-relative tw-rounded-5 tw-shadow dark:tw-bg-gray-700">
    <div class="tw-bg-white tw-rounded-5 tw-p-[30px]">
      <div class="tw-flex tw-flex-col tw-grow tw-gap-4">
        <div class="tw-flex tw-flex-col tw-gap-4 tw-text-center">
          <h2 aria-label="Session expired title" class="tw-text-[26px] tw-font-bold">
            {{ 'label.session-timeout.expired-title.text' | translate }}
          </h2>
        </div>
        <div aria-label="Session expired description container" class="tw-w-full tw-text-center">
          <span aria-label="Session expired description label">{{
            'label.session-timeout.expired-description.text' | translate
          }}</span>
        </div>
        <div class="tw-justify-center tw-flex tw-items-center tw-mt-auto tw-gap-2.5">
          <button
            (click)="logInAgain()"
            type="button"
            aria-label="Session expired ok button"
            class="tw-btn-primary tw-btn xs:tw-w-auto tw-w-full tw-px-5 tw-whitespace-nowrap"
          >
            {{ 'notification.button.ok' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
