<div *ngIf=" (vouchers$ | async) as vouchers" aria-label="ScootForSure voucher section" class="tw-mb-[2rem]">
  <!-- Header -->
  <div
    (click)="onToggleVoucher(!showVoucher)"
    class="tw-w-full tw-px-[30px] tw-py-5 tw-bg-yellow-100 tw-rounded-tl-[20px] tw-rounded-tr-[20px] tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-inline-flex"
    [ngClass]="{ 'tw-rounded-bl-[20px] tw-rounded-br-[20px]': !showVoucher }"
  >
    <div class="tw-self-stretch tw-justify-start tw-items-center tw-gap-2.5 tw-inline-flex">
      <div class="tw-w-9 tw-h-9 tw-relative">
        <img
          aria-label="ScootForSure voucher image icon"
          class="tw-w-9 tw-h-9"
          [src]="'/assets/icons/svg/voucher.svg'"
        />
      </div>
      <div
        aria-label="ScootForSure voucher header label"
        class="tw-grow tw-shrink tw-basis-0 tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
      >
        {{ 'label.book-flight-payment.voucher.title.label' | translate }}
      </div>
      <div class="tw-justify-start tw-items-start tw-gap-5 tw-flex">
        <div class="tw-justify-start items-center tw-gap-[5px] tw-flex">
          <div class="tw-w-6 tw-h-6 tw-relative">
            <img
              aria-label="ScootForSure voucher collapse/expand arrow icon"
              class="tw-cursor-pointer tw-transition-transform tw-duration-300 tw-ease-out"
              [ngClass]="{ 'tw-rotate-180': !showVoucher }"
              src="/assets/icons/svg/Chev-Arrow.svg"
              alt="Collapse/Expand"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div
    aria-label="ScootForSure voucher details"
    class="tw-w-full tw-p-[30px] tw-bg-white  tw-shadow tw-flex-col tw-justify-center tw-items-start tw-gap-5 tw-inline-flex"
    *ngIf="showVoucher"
  >
    <div aria-label="ScootForSure voucher banner"
      class="tw-self-stretch tw-px-5 tw-py-[15px] tw-bg-stone-900 tw-rounded-[10px] tw-flex-col tw-justify-center tw-items-center tw-gap-2.5 tw-flex"
    >
      <div class="tw-text-yellow-400 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7">
        <span aria-label="ScootForSure voucher banner label">
          {{ 'label.book-flight-payment.voucher.black-banner.heading.label' | translate }}
        </span>
        <span aria-label="ScootForSure voucher number of points redeemed label">{{ totalRedeemedPoints }}</span>
        <span>/</span>
        <span aria-label="ScootForSure voucher number of points from total fare label">{{ totalFarePoints }}</span>
        {{ 'label.book-flight.points.label' | translate }}
      </div>
    </div>
    <div aria-label="ScootForSure voucher info label"
      class="tw-self-stretch tw-text-stone-900 tw-text-[15px] tw-font-medium tw-font-['Montserrat'] tw-leading-normal"
    >
      {{ 'label.book-flight-payment.voucher.info.label' | translate }}
    </div>
    <div aria-label="ScootForSure voucher list"
      class="tw-justify-start tw-mb-0 tw-items-start tw-w-full xs:tw-w-auto xs:tw-grid xs:tw-grid-cols-4 xs:tw-gap-[30px]">
      <div
        *ngFor="let voucher of vouchers; index as index"
        [ngClass]="{
          'tw-bg-yellow-100': voucher.isSelected,
          'tw-bg-zinc-100': !voucher.isSelected
        }"
        class="tw-px-[80px] tw-rounded-[20px] tw-flex-col tw-justify-center tw-items-center tw-gap-2.5 tw-mb-[20px] xs:tw-pb-0 tw-w-full xs:tw-w-auto xs:tw-inline-flex"
      >
        <div class="tw-flex-col tw-justify-start tw-items-center tw-flex tw-p-[15px]">
          <div
            attr.aria-label="{{ voucher.voucherNumber }} Voucher tile ScootForSure label"
            class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
          >
            {{ 'label.book-flight.scootforsure.label' | translate }}
          </div>
          <div class="tw-justify-start tw-items-center tw-gap-2.5 tw-inline-flex">
            <div
              attr.aria-label="{{ voucher.voucherNumber }} Voucher tile points label"
              class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
            >
              {{ voucher.amount }} {{ 'label.book-flight.points.label' | translate }}
            </div>
          </div>
          <div
            attr.aria-label="{{ voucher.voucherNumber }} Voucher tile expiry date label"
            class="tw-text-center tw-text-stone-900 tw-text-[13px] tw-font-medium tw-leading-[18px]"
          >
            {{ 'label.book-flight.expiry.label' | translate }}: {{ voucher.expiry | date : 'dd MMM yyyy' }}
          </div>
          <div class="tw-pt-[10px]">
            <button
              attr.aria-label="{{ voucher.voucherNumber }} Voucher tile button"
              (click)="onSelectVoucher(voucher, !voucher.isSelected)"
              [disabled]="!hasBalanceDue() && !voucher.isSelected"
              [ngClass]="{
                'tw-btn-primary tw-w-32': voucher.isSelected,
                'tw-btn-tertiary tw-w-26': !voucher.isSelected
              }"
              class="tw-btn-select"
            >
              {{ (voucher.isSelected ? 'label.button.selected.label' : 'label.button.select.label') | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div aria-label="ScootForSure voucher footer"
      class="tw-w-full tw-bg-yellow-20 tw-rounded-bl-[20px] tw-px-[30px] tw-rounded-br-[20px] tw-py-5 tw-flex-col tw-justify-end xs:tw-items-end tw-gap-5 tw-inline-flex"
      *ngIf="showVoucher">
      <div class="xs:tw-basis-auto tw-basis-1/2">
        <button
          class="tw-btn tw-btn-primary tw-px-5"
          aria-label="Voucher apply button"
          (click)="onApply(vouchers)"
        >
          {{ 'label.button.apply.label' | translate }}
        </button>
      </div>
  </div>
</div>
