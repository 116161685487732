<!--Begin Booking header section-->
<div
  [attr.aria-label]="'Header section for Booking - ' + bookingIndex"
  [ngClass]="{ 'tw-rounded-bl-[20px] tw-rounded-br-[20px]': !showDetails }"
  class="tw-w-full tw-p-5 tw-bg-yellow-100 tw-rounded-tl-[20px] tw-rounded-tr-[20px] tw-justify-start tw-items-center tw-gap-[948px] tw-inline-flex"
>
  <div
    [attr.aria-label]="'Flight accordion for Booking - ' + bookingIndex"
    (click)="showDetails = tabName !== tabMenu.Cancelled ? !showDetails : false"
    class="tw-cursor-pointer tw-grow tw-shrink tw-basis-0 tw-justify-start tw-items-center tw-gap-2.5 tw-flex"
  >
    <div class="tw-w-9 tw-h-9 tw-relative">
      <div class="tw-w-9 tw-h-9 tw-left-0 tw-top-0 tw-absolute tw-bg-white tw-rounded-full tw-shadow"></div>
      <div class="tw-w-5 tw-h-5 tw-left-[8px] tw-top-[8px] tw-absolute">
        <img
          [attr.aria-label]="'Header icon for Booking - ' + bookingIndex"
          src="/assets/icons/svg/airplane.svg"
          alt="airplane"
        />
      </div>
    </div>
    <div class="tw-grow tw-shrink tw-basis-0 tw-flex tw-flex-col">
      <span
        *ngIf="tabName !== tabMenu.Cancelled && booking.journeys.length > 0"
        [attr.aria-label]="'Departing - Return Country for Booking - ' + bookingIndex"
        class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
        >{{ booking.journeys[0].originCountry }} - {{ booking.journeys[0].destinationCountry }}
      </span>
      <span
        [attr.aria-label]="'Booking Number for Booking - ' + bookingIndex"
        class="text-stone-900 text-lg font-medium leading-7"
        >{{ booking.recordLocator }}</span
      >
    </div>
    <div
      *ngIf="tabName !== tabMenu.Cancelled"
      [attr.aria-label]="'Expand - Collapse button for Booking - ' + bookingIndex"
      class="tw-justify-start tw-items-start tw-gap-5 tw-flex tw-cursor-pointer"
    >
      <img
        [attr.aria-label]="'Collapse/Expand arrow icon for Booking - ' + bookingIndex"
        class="tw-cursor-pointer tw-transition-transform tw-duration-300 tw-ease-out"
        [ngClass]="{ 'tw-rotate-180': !showDetails }"
        src="/assets/icons/svg/Chev-Arrow.svg"
        alt="Collapse/Expand"
      />
    </div>
  </div>
</div>
<!--End Booking header section-->
<!--Begin Booking Journey Details-->
<div
  [attr.aria-label]="'Flight details section for Booking - {{ bookingIndex }}'"
  [class.tw-hidden]="!showDetails"
  class="tw-w-full tw-px-5 tw-py-5 tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-inline-flex tw-border tw-border-neutral-200 tw-bg-white tw-rounded-bl-2xl tw-rounded-br-2xl"
>
  <!--Start Flight-->
  <div [attr.aria-label]="'Flight category section for Booking - ' + bookingIndex" class="tw-w-full category-div">
    <div [attr.aria-label]="'Flight category label for Booking - ' + bookingIndex" class="category-label">
      {{ 'label.manage-booking.flight.label' | translate }}
    </div>
    <div *ngFor="let journey of booking.journeys; index as journeyIndex">
      <div class="tw-justify-start tw-items-start xs:tw-flex">
        <div
          [attr.aria-label]="'Flight section for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
          class="tw-flex-col tw-justify-start tw-items-start tw-gap-2.5 xs:tw-flex"
        >
          <div class="tw-flex-col tw-justify-start tw-items-start tw-gap-[5px] xs:tw-flex">
            <!--Begin Journey Header-->
            <div
              [attr.aria-label]="'Flight header for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
              class="tw-text-stone-900 tw-py-[10px] xs:tw-py-[0px] tw-text-[15px] tw-leading-normal"
            >
              <div *ngIf="journeyIndex === 0">
                {{ 'label.manage-booking.departing.label' | translate }}
                <div
                  class="tw-mr-[10px] tw-h-[30px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block"
                  [ngClass]="{
                    'tw-bg-slate-200': booking.journeys[0].flightClassOfService === flightFareType.Economy,
                    'tw-bg-yellow-300': booking.journeys[0].flightClassOfService === flightFareType.ScootPlus
                  }"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="Departing flight class service"
                  >
                    {{ booking.journeys[0].flightClassOfService }}
                  </div>
                </div>
                <div
                  *ngIf="booking.scootForSureFlight"
                  class="tw-mr-[10px] tw-h-[30px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block tw-bg-yellow-100"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="ScootForSure label"
                  >
                    {{ 'label.manage-booking.flight-item.scoot-for-sure.label' | translate }}
                  </div>
                </div>
                <div
                  *ngIf="booking.isFocBooking"
                  class="tw-bg-green-100 tw-h-[30px] tw-w-[80px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="FOC label"
                  >
                    {{ 'label.manage-booking.flight-item.foc.label' | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="journeyIndex === 1">
                {{ 'label.manage-booking.return.label' | translate }}
                <div
                  class="tw-mr-[10px] tw-h-[30px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block"
                  [ngClass]="{
                    'tw-bg-slate-200': booking.journeys[1].flightClassOfService === flightFareType.Economy,
                    'tw-bg-yellow-300': booking.journeys[1].flightClassOfService === flightFareType.ScootPlus
                  }"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="Returning flight class service"
                  >
                    {{ booking.journeys[1].flightClassOfService }}
                  </div>
                </div>
                <div
                  *ngIf="booking.scootForSureFlight"
                  class="tw-mr-[10px] tw-h-[30px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block tw-bg-yellow-100"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="ScootForSure label"
                  >
                    {{ 'label.manage-booking.flight-item.scoot-for-sure.label' | translate }}
                  </div>
                </div>
                <div
                  *ngIf="booking.isFocBooking"
                  class="tw-bg-green-100 tw-h-[30px] tw-w-[80px] tw-px-[5px] tw-py-[3px] tw-rounded tw-justify-end tw-gap-[-5px] tw-inline-block"
                >
                  <div
                    class="tw-text-center tw-text-black tw-text-[15px] tw-font-bold tw-leading-normal"
                    aria-label="FOC label"
                  >
                    {{ 'label.manage-booking.flight-item.foc.label' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!--End Journey Header-->
          </div>
          <div
            [attr.aria-label]="'Flight details for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
            class="tw-w-full tw-h-full tw-flex-col tw-justify-start tw-items-start xs:tw-flex"
          >
            <!--Begin Journey Segment-->
            <div
              class="tw-px-[15px] tw-py-5 tw-bg-white tw-rounded-tl-[20px] tw-rounded-tr-[20px] tw-border tw-border-neutral-200 tw-flex-col tw-justify-start tw-items-start tw-gap-[15px] tw-flex"
            >
              <div class="tw-w-full tw-justify-end tw-items-start tw-gap-2 tw-inline-flex">
                <!--Begin Journey Segment Icons-->
                <div class="tw-self-stretch tw-flex-col tw-justify-between tw-items-center tw-flex">
                  <div class="tw-w-6 tw-h-6 tw-relative">
                    <img
                      [attr.aria-label]="
                        'Flight takeoff icon for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                      "
                      class="tw-w-6 tw-h-6 tw-left-[1.84px] tw-top-[3.57px] tw-absolute"
                      src="/assets/icons/svg/flight-takeoff.svg"
                    />
                  </div>
                  <div class="tw-h-[63%] break-line"></div>
                  <div class="tw-w-6 tw-h-6 tw-relative">
                    <img
                      [attr.aria-label]="'Map pin icon for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                      class="tw-w-6 tw-h-6 tw-left-[1px] tw-top-[-2px] tw-absolute"
                      src="/assets/icons/svg/map-pin.svg"
                    />
                  </div>
                </div>
                <!--End Journey Segment Icons-->
                <!--Begin Journey Segment Details-->
                <div
                  class="xs:tw-min-w-[280px] tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-inline-flex"
                >
                  <!--Begin Departure-->
                  <div class="tw-w-full tw-justify-start tw-items-start tw-inline-flex tw-gap-2">
                    <div class="tw-w-full tw-flex-col tw-justify-start tw-items-start tw-inline-flex">
                      <div
                        [attr.aria-label]="
                          'Departure Country for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-h-min-7 tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
                      >
                        {{ journey.originCountry }}
                      </div>
                      <div
                        [attr.aria-label]="
                          'Departure Airport Name for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                      >
                        {{ journey.originTerminal }}
                      </div>
                    </div>
                    <div class="tw-w-full tw-flex-col tw-justify-start tw-items-end tw-inline-flex">
                      <div
                        [attr.aria-label]="
                          'Departure Time for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-text-right tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
                      >
                        {{ journey.originTimeOfDeparture }}
                      </div>
                      <div
                        [attr.aria-label]="
                          'Departure Date for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-text-right tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                      >
                        {{ journey.originDateOfDeparture }}
                      </div>
                    </div>
                  </div>
                  <!--End Departure-->
                  <div
                    *ngIf="journey.stop > 0"
                    class="tw-self-stretch tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                  >
                    <div class="tw-relative">
                      <img
                        [attr.aria-label]="'Circle icon for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                        class="tw-w-6 tw-h-6 tw-left-[-32px] tw-top-[-4px] tw-absolute"
                        src="/assets/icons/svg/circle.svg"
                      />
                    </div>
                    <div [attr.aria-label]="'Stop for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex">
                      {{ journey.stop }}
                      {{
                        (journey.stop > 1 ? 'label.manage-booking.stops.label' : 'label.manage-booking.stop.label')
                          | translate
                      }}
                    </div>
                  </div>
                  <!--Begin Arrival-->
                  <div class="tw-w-full tw-justify-start tw-items-start tw-inline-flex tw-gap-2">
                    <div class="tw-w-full tw-flex-col tw-justify-start tw-items-start tw-inline-flex">
                      <div
                        [attr.aria-label]="
                          'Arrival Country for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
                      >
                        {{ journey.destinationCountry }}
                      </div>
                      <div
                        [attr.aria-label]="
                          'Arrival Airport Name for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                        "
                        class="tw-self-stretch tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                      >
                        {{ journey.destinationTerminal }}
                      </div>
                    </div>
                    <div class="tw-w-full tw-flex-col tw-justify-start tw-items-end tw-inline-flex">
                      <div
                        [attr.aria-label]="'Arrival Time for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                        class="tw-self-stretch tw-text-right tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7"
                      >
                        {{ journey.destinationTimeOfArrival }}
                      </div>
                      <div
                        [attr.aria-label]="'Arrival Date for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                        class="tw-self-stretch tw-text-right tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                      >
                        {{ journey.destinationDateOfArrival }}
                      </div>
                    </div>
                  </div>
                  <!--End Arrival-->
                </div>
                <!--End Journey Segment Details-->
              </div>
            </div>
            <!--End Journey Segment-->
            <div
              class="tw-h-[54px] tw-p-[15px] tw-bg-white tw-rounded-bl-[20px] tw-rounded-br-[20px] tw-border tw-border-neutral-200 tw-flex-col tw-justify-center tw-items-start tw-gap-[5px] tw-flex tw-w-full"
            >
              <div class="tw-self-stretch tw-h-6 tw-justify-between tw-items-center tw-inline-flex">
                <div class="tw-justify-start tw-items-center tw-gap-[5px] tw-flex">
                  <div
                    [attr.aria-label]="'Operated by for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                    class="tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                  >
                    {{ 'label.manage-booking.operated-by.label' | translate }}
                  </div>
                  <div class="tw-justify-start tw-items-center tw-gap-2.5 tw-flex">
                    <div
                      class="tw-w-6 tw-h-6 tw-pr-[0.07px] tw-pt-[0.07px] tw-pb-[0.12px] tw-justify-center tw-items-center tw-flex"
                    >
                      <div class="tw-w-[23.93px] tw-h-[23.82px] tw-relative">
                        <div class="tw-w-[23.93px] tw-h-[23.82px] tw-left-0 tw-top-0 tw-absolute"></div>
                        <img
                          [attr.aria-label]="
                            'Scoot Operator icon for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex
                          "
                          class="tw-w-full tw-h-full"
                          src="/assets/icons/svg/scoot-operator.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    [attr.aria-label]="'Aircraft Type for Booking - ' + bookingIndex + ' Journey - ' + journeyIndex"
                    class="tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-[18px]"
                  >
                    {{ journey.aircraftType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Flight-->
  <div class="line"></div>
  <!--Start Passenger-->
  <div [attr.aria-label]="'Passenger category section for Booking - ' + bookingIndex" class="category-div">
    <div [attr.aria-label]="'Passenger category label for Booking - ' + bookingIndex" class="category-label">
      {{ 'label.manage-booking.passenger.label' | translate }}
    </div>
    <div
      [attr.aria-label]="'Passenger category details for Booking - ' + bookingIndex"
      class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7"
      [innerHTML]="booking.passengers | safe : 'html'"
    ></div>
  </div>
  <!--End Passenger-->
  <div class="line"></div>
  <!--Start Amount-->
  <div [attr.aria-label]="'Amount category section for Booking - ' + bookingIndex" class="category-div">
    <div [attr.aria-label]="'Amount category label for Booking - ' + bookingIndex" class="category-label">
      {{ 'label.manage-booking.amount.label' | translate }}
    </div>
    <div
      [attr.aria-label]="'Amount category details for Booking - ' + bookingIndex"
      class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7"
    >
      {{ booking.amount }}
    </div>
  </div>
  <!--End Amount-->
  <!--Start Manage flight-->
  <div *ngIf="tabName === tabMenu.Upcoming" class="tw-w-full">
    <div class="line"></div>
    <div
      [attr.aria-label]="'Manage flight category section for Booking - ' + bookingIndex"
      class="tw-self-stretch tw-pt-[20px] category-div tw-w-full"
    >
      <div
        [attr.aria-label]="'Manage flight category label for Booking - ' + bookingIndex"
        class="category-label tw-mb-[10px]"
      >
        {{ 'label.manage-booking.manage-flight.label' | translate }}
      </div>
      <div
        [attr.aria-label]="'Manage flight category details for Booking - ' + bookingIndex"
        class="tw-self-stretch tw-grow"
      >
        <div class="tw-gap-2.5 xs:tw-flex tw-w-full tw-justify-between group">
          <button
            *ngIf="!booking.scootForSureFlight"
            (click)="changeFlight(booking)"
            [id]="'changeFlight' + bookingIndex"
            [attr.aria-label]="'Change flight button for Booking - ' + bookingIndex"
            class="tw-btn tw-btn-tertiary manage-flight-button"
          >
            {{ 'label.manage-booking.change-flight.button' | translate }}
          </button>
          <button
            [id]="'manageAddOns' + bookingIndex"
            (click)="manageAddOns(booking)"
            [attr.aria-label]="'Manage add-ons button for Booking - ' + bookingIndex"
            class="tw-btn tw-btn-tertiary manage-flight-button"
          >
            {{ 'label.manage-booking.manage-add-ons.button' | translate }}
          </button>
          <button
            [id]="'resendItinerary' + bookingIndex"
            (click)="resendItinerary(booking)"
            [attr.aria-label]="'Resend itinerary button for Booking - ' + bookingIndex"
            class="tw-btn tw-btn-tertiary manage-flight-button"
          >
            {{ 'label.manage-booking.resend-itinerary.button' | translate }}
          </button>

          <button
            *ngIf="!booking.scootForSureFlight"
            (click)="cancelFlight(booking)"
            [id]="'cancelFlight' + bookingIndex"
            [attr.aria-label]="'Cancel booking button for Booking - ' + bookingIndex"
            [disabled]="!booking.isAllowedToCancel"
            class="cancel-button tw-btn tw-btn-tertiary manage-flight-button tw-text-center tw-flex tw-items-center tw-justify-center"
          >
            {{ 'label.manage-booking.cancel-booking.button' | translate }}
          </button>
          <div class="tw-relative cancel-tooltip-wrapper">
            <div class="cancel-tooltip" [attr.aria-label]="'Cancel tooltip message for Booking - ' + bookingIndex">
              {{ 'label.manage-booking.cancel-flight.tooltip.message.label' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Manage flight-->
</div>
<!--End Booking Journey Details-->
