import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { SessionService } from './session.service';
import { SKIP_AUTHORIZATION } from '../shared/model/common.model';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipAuthorization = request.context.get(SKIP_AUTHORIZATION);
    if (skipAuthorization) {
      return next.handle(request);
    }

    return this.sessionService.sessionToken$.pipe(
      switchMap((token) => {
        const newRequest = request.clone({
          setHeaders: { Authorization: token }
        });
        return next.handle(newRequest);
      })
    );
  }
}
