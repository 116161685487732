import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BannerDefault } from '../header.enum';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { HeaderService } from '../header.service';
import { Observable, Subject, filter, first, takeUntil } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { MsalAuthService } from '../../shared/service/msal-auth/msal-auth.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AccountService } from '../../account/account.service';
import { LoginRequest, MyIDTravelRequest } from '../../account/account.model';
import { Router } from '@angular/router';
import { AppConstants } from '../../app.constants';
import { SessionService } from '../../session/session.service';
import { MyIDTravelLogInResponse } from '../../session/session.model';
import { ResourceService } from '../../shared/service/resources/resources.service';
import { BookingFlightTypeEnum, FlightClassOfServiceEnum } from '../../booking/flight/flight.enum';
import { Currency } from '../../shared/service/resources/resource.model';
import { ExternalRate } from '@it-application-hub/bookings/lib/booking-models/booking-state.models';
import { BookingService } from '../../booking/booking.service';
import { ErrorService } from '../../error/error.service';
import { ErrorEnum, ErrorMessageEnum } from '../../error/error.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-header-items',
  templateUrl: './header-items.component.html',
  styleUrls: ['./header-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderItemsComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  readonly pageRoute = PageRoute;
  readonly banner = {
    Currency: BannerDefault.Currency,
    SQOALUrl: BannerDefault.SQOALUrl,
    TotalSummary: 0.0
  };

  showCurrencyModal!: boolean;
  showDropDownMenu = false;
  isAuthenticated = false;
  currentPage$?: Observable<string>;
  currencyCode$?: Observable<string>;
  currencies$?: Observable<Currency[]>;
  externalRates$?: Observable<ExternalRate[]>;
  isMobile = false;
  canShowCurrency = false;
  isCadetPilot = false;

  constructor(
    private headerService: HeaderService,
    private accountService: AccountService,
    private sessionService: SessionService,
    private msalAuthService: MsalAuthService,
    private msalBroadcastService: MsalBroadcastService,
    private resourceService: ResourceService,
    private bookingService: BookingService,
    private errorService: ErrorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentPage$ = this.headerService.currentPage$;
    this.currencyCode$ = this.headerService.currencyCode$;
    this.isAuthenticated = this.isAuthorized();
    this.onLogin();

    this.headerService.currentPage$.pipe(takeUntil(this._destroy$)).subscribe((currentPage) => {
      this.canShowCurrency = false;
      if (this.isAuthorized()) {
        this.isCadetPilot = this.accountService.getUserInfo()?.isCadetPilot ?? false;
        this.resourceService.settings$.pipe(takeUntil(this._destroy$)).subscribe((setting) => {
          if (setting?.showMCP) {
            this.showCurrencySelection(currentPage);
          }
        });
      }
    });

    this.msalAuthService.acquireTokenSilent$.pipe(takeUntil(this._destroy$)).subscribe((payload) => {
      if (payload) {
        this.sessionService.setIdTokenToSessionStorage(payload.idToken);
        this.login({
          idToken: payload.idToken
        });
      }
    });
  }

  onSQOAL(): void {
    const request: MyIDTravelRequest = {
      idToken: this.sessionService.getCurrentUserIdTokenFromSessionStorage()
    };

    this.accountService.myIDTravelLogIn$(request).subscribe((response: MyIDTravelLogInResponse) => {
      if (response.isSuccess) {
        window.location.href = response.redirectUrl;
      }
    });
  }

  onLogin(): void {
    if (this.msalAuthService.hasSSOLogin) {
      this.msalSubject();
      this.msalInProgress();
    } else {
      this.login();
    }
  }

  onLogout(): void {
    if (this.sessionService.authenticated) this.accountService.logout$();

    this.sessionService.clearSessionStorage();
    if (this.msalAuthService.hasSSOLogin) this.msalAuthService.logout();
    else window.location.href = PageRoute.Home;
  }

  onShowCurrencySelection() {
    this.showCurrencyModal = this.canShowCurrency;
    if (this.bookingService.classOfService === FlightClassOfServiceEnum.ScootForSure) {
      this.showCurrencyModal = false;
    }
  }

  closeCurrencySelection() {
    this.showCurrencyModal = false;
    this.headerService.setCurrencyCode();
  }

  private showCurrencySelection(currentPage: string) {
    if (
      (currentPage === PageRoute.BookFlightSelection || currentPage === PageRoute.BookFlightPayment) &&
      this.bookingService.bookingType === BookingFlightTypeEnum.NewBooking
    ) {
      this.currencies$ = this.resourceService.getCurrencies$();
      this.externalRates$ = this.resourceService.externalRates$;
      this.canShowCurrency = true;
    }
  }

  private msalSubject() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroy$),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalAuthService.setActiveAccount(payload.account);
        this.login({
          idToken: payload.idToken
        });
      });
  }

  private msalInProgress() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        if (!this.msalAuthService.authenticated) {
          this.sessionService.clearSessionStorage();
          this.msalAuthService.login();
        } else {
          this.msalAuthService.acquireTokenSilent();
        }
      });
  }

  private login(request: LoginRequest | null = null): void {
    if (!this.sessionService.authenticated && !this.accountService.isLogInCalled) {
      this.accountService.isLogInCalled = true;
      this.accountService
        .login$(request)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.isAuthenticated = this.isAuthorized();
            if (response) this.router.navigateByUrl(PageRoute.BookFlight);
            else this.errorService.handleError(ErrorEnum.Unauthorized);
          },
          error: (err: HttpErrorResponse) => {
            if (err.error === ErrorMessageEnum.SSOAuthenticationInvalid) {
              this.sessionService.clearSessionStorage();
              this.msalAuthService.login();
            }
            else {
              this.isAuthenticated = this.isAuthorized();
              this.errorService.handleError(err.status, err.error);
            }
          }
        });
    } else {
      this.isAuthenticated = this.isAuthorized();
    }
  }

  private isAuthorized(): boolean {
    return this.msalAuthService.hasSSOLogin ? this.msalAuthService.authenticated : this.sessionService.authenticated;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const isMobile = window.innerWidth < AppConstants.MobileWidth.xs;
    if (isMobile) {
      this.showDropDownMenu = false;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
