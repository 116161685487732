import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { SessionExpireService } from './session-expire.service';
import { SKIP_RESET_SESSION } from '../shared/model/common.model';

@Injectable()
export class SessionExpireInterceptor implements HttpInterceptor {
  constructor(private sessionExpireService: SessionExpireService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipResetSession = request.context.get(SKIP_RESET_SESSION);
    if (skipResetSession) {
      return next.handle(request);
    }

    return next.handle(request).pipe(tap(() => {
      if (this.sessionExpireService.isTokenExpired) {
        this.sessionExpireService.showSessionExpiredPopup();
      }
      else {
        this.sessionExpireService.reset();
      }
    }));
  }
}
