import { Injectable } from '@angular/core';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from '../../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthService {
  private readonly _acquireTokenSilent$ = new BehaviorSubject<AuthenticationResult | null>(null);
  
  readonly acquireTokenSilent$ = this._acquireTokenSilent$.asObservable();

  constructor(
    private msalService: MsalService
  ) {}

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  setActiveAccount(account: AccountInfo | null){
    this.msalService.instance.setActiveAccount(account);
  }

  acquireTokenSilent() {
    if (this.activeAccount && this.hasSSOLogin) {
      this.msalService.instance.acquireTokenSilent({
        scopes: ["user.read"],
        account: this.activeAccount
      })
      .then((result) => {
        this.setActiveAccount(result.account);
        this._acquireTokenSilent$.next(result);
      })
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          this.msalService.loginRedirect();
        }
      });
    }
  }

  get activeAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  get authenticated(): boolean {
    return !!this.msalService.instance.getActiveAccount();
  }

  get hasSSOLogin(): boolean {
    return environment.singleSignOn.authority.trim().length > 0;
  }
}
