<div *ngIf="profileData$ | async as profile">
  <div aria-label="Profile header" class="tw-realtive tw-w-[100%] tw-h-auto tw-min-h[154px] tw-bg-white tw-z-10">
    <div class="scoot-container tw-mx-auto tw-py-[30px]">
      <div
        class="tw-flex tw-flex-wrap xs:tw-gap-[22px] xs:tw-items-start xs:tw-justify-start xxs:tw-items-center xxs:tw-justify-center"
      >
        <div class="tw-flex tw-justify-center tw-h-[94px] xs:tw-w-[94px] xxs:tw-w-[100%]">
          <img aria-label="Profile Avatar Image" src="/assets/icons/svg/profile-header-icon.svg" alt="Profile Avatar" />
        </div>
        <div class="tw-w-[200px] tw-h-[94px] tw-flex tw-flex-wrap tw-align-center">
          <div class="tw-w-[100%] tw-flex tw-items-end xs:tw-justify-start xxs:tw-justify-center">
            <span aria-label="Profile user name" class="tw-font-bold tw-text-stone-900 tw-text-[26px]">{{
              profile.fullName
            }}</span>
          </div>
          <div class="tw-w-[100%] tw-flex tw-items-start xs:tw-justify-start xxs:tw-justify-center">
            <span aria-label="Profile user email address" class="tw-font-bold tw-text-stone-900 tw-text-[15px]">{{
              profile.email
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tw-realtive tw-w-[100%] tw-h-auto tw-min-h[154px] tw-z-10">
    <div class="scoot-container tw-mx-auto tw-py-[20px]">
      <div
        class="tw-flex tw-flex-wrap tw-gap-[22px] xs:tw-items-start xs:tw-justify-start xxs:tw-items-center xxs:tw-justify-center xxs:tw-px-[10px]"
      >
        <!--Menu-->
        <div class="tw-overflow-hidden xs:tw-w-[254px] xs:tw-bg-white xs:tw-rounded-[20px] xxs:tw-w-[100%]">
          <div class="tw-py-[15px] xs:tw-px-[20px] tw-flex tw-items-center tw-justify-start tw-gap-[5px]">
            <span
              class="tw-text-stone-900 tw-font-bold tw-font-['Montserrat'] tw-leading-7 xs:tw-text-lg xxs:tw-text-[26px]"
              >{{ 'label.profile.my-voucher.my-account.label' | translate }}
            </span>
          </div>
          <div
            class="tw-overflow-hidden xxs:tw-bg-white xs:tw-rounded-t-none xs:tw-rounded-b-[10%] xxs:tw-rounded-[20px]"
          >
            <div
              class="xs:tw-hidden tw-flex tw-flex-inline tw-items-center tw-justify-between tw-py-[10px] tw-px-[20px]"
              (click)="showMenu = !showMenu"
            >
              <div class="tw-flex tw-flex-inline tw-items-center tw-justify-center tw-gap-[5px]">
                <img
                  aria-label="Profile dropdown icon"
                  [src]="selectedMenu.icon"
                  alt="Profile Icon"
                  class="tw-w-[24px]"
                />
                <button
                  aria-label="Profile dropdown button"
                  class="tw-text-black tw-text-stone-900 tw-text-lg tw-font-['Montserrat'] tw-leading-7"
                >
                  <span aria-label="Profile dropdown title">{{ selectedMenu.title | translate }}</span>
                </button>
              </div>
              <img
                [ngClass]="{ 'tw-rotate-[180deg]': showMenu }"
                class="tw-transition-transform"
                src="/assets/icons/svg/arrow-down.svg"
              />
            </div>
            <ul class="tw-w-[100%]" *ngIf="showMenu">
              <li *ngIf="(isMobile && selectedMenu.title !== profileMenuTitle.profile) || !isMobile">
                <button
                  aria-label="Profile Profile button"
                  class="nav-button"
                  (click)="onSelectMenu(profileMenuTitle.profile, profileMenuIcon.profile)"
                  [ngClass]="{ 'tw-font-bold tw-bg-yellow-100': selectedMenu.title === profileMenuTitle.profile }"
                >
                  <img [src]="profileMenuIcon.profile" alt="Profile Icon" class="img-icon" />
                  <span aria-label="Profile Profile label" class="label-text">
                    {{ profileMenuTitle.profile | translate }}
                  </span>
                </button>
              </li>
              <li
                *ngIf="profile.isUnionMember && 
                  ((isMobile && selectedMenu.title !== profileMenuTitle.focEntitlement) || !isMobile)
                "
              >
                <button
                  aria-label="Profile FOC Entitlement button"
                  class="nav-button"
                  (click)="onSelectMenu(profileMenuTitle.focEntitlement, profileMenuIcon.focEntitlement)"
                  [ngClass]="{
                    'tw-font-bold tw-bg-yellow-100': selectedMenu.title === profileMenuTitle.focEntitlement
                  }"
                >
                  <img [src]="profileMenuIcon.focEntitlement" alt="FOC Entitlement Icon" class="img-icon" />
                  <span aria-label="Profile FOC Entitlement label" class="label-text">
                    {{ profileMenuTitle.focEntitlement | translate }}
                  </span>
                </button>
              </li>
              <li *ngIf="(isMobile && selectedMenu.title !== profileMenuTitle.travelBeneficiaries) || !isMobile">
                <button
                  aria-label="Profile Travel Beneficiaries button"
                  class="nav-button"
                  (click)="onSelectMenu(profileMenuTitle.travelBeneficiaries, profileMenuIcon.travelBeneficiaries)"
                  [ngClass]="{
                    'tw-font-bold tw-bg-yellow-100': selectedMenu.title === profileMenuTitle.travelBeneficiaries
                  }"
                >
                  <img [src]="profileMenuIcon.travelBeneficiaries" alt="Travel Beneficiaries" class="img-icon" />
                  <span aria-label="Profile Travel Beneficiaries label" class="label-text">
                    {{ profileMenuTitle.travelBeneficiaries | translate }}
                  </span>
                </button>
              </li>
              <li *ngIf="(isMobile && selectedMenu.title !== profileMenuTitle.myVouchers) || !isMobile">
                <button
                  aria-label="Profile My Voucher button"
                  class="nav-button"
                  (click)="onSelectMenu(profileMenuTitle.myVouchers, profileMenuIcon.myVouchers)"
                  [ngClass]="{ 'tw-font-bold tw-bg-yellow-100': selectedMenu.title === profileMenuTitle.myVouchers }"
                >
                  <img [src]="profileMenuIcon.myVouchers" alt="My Voucher Icon" class="img-icon" />
                  <span aria-label="Profile My Voucher label" class="label-text">
                    {{ profileMenuTitle.myVouchers | translate }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!--Details-->
        <div class="xs:tw-w-[calc(100%-276px)] xxs:tw-w-[100%]">
          <app-profile-details
            *ngIf="selectedMenu.title === profileMenuTitle.profile"
            [profileData]="profile"
          ></app-profile-details>
          <app-profile-foc-entitlement
            *ngIf="selectedMenu.title === profileMenuTitle.focEntitlement"
            [profileData]="profile"
          ></app-profile-foc-entitlement>
          <app-profile-my-voucher
            *ngIf="selectedMenu.title === profileMenuTitle.myVouchers"
          ></app-profile-my-voucher>
          <app-profile-travel-beneficiaries
            *ngIf="selectedMenu.title === profileMenuTitle.travelBeneficiaries"
            [profileData]="profile"
          ></app-profile-travel-beneficiaries>
        </div>
      </div>
    </div>
  </div>
</div>
