import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsModuleService } from '@it-application-hub/payments';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { PaymentService } from './payment.service';
import { environment } from '../../../environments/environment';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { UserInformation } from '@it-application-hub/payments/lib/payments-page/payments-identity.service';
import { SessionService } from '../../session/session.service';
import { FlightSearchService } from '../flight/flight-search/flight-search.service';
import { FlightService } from '../flight/flight.service';
import { AppConstants } from '../../app.constants';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  userInformation!: UserInformation;
  constructor(
    private router: Router,
    private paymentsPageService: PaymentsModuleService,
    private paymentService: PaymentService,
    private flightService: FlightService,
    private bookingService: BookingService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.paymentService.submitPayment$
      .pipe(takeUntil(this._destroy$))
      .subscribe(async () => {
        this.paymentService.paymentOnProcess(true);
        await firstValueFrom(this.paymentsPageService.submitPayment$()).then((response) => {
          if (response.status) {
            this.router.navigate([PageRoute.Wait], {
              state: {
                paymentReference: response.paymentReference
              }
            });
          } else {
            this.paymentService.paymentOnProcess(false);
          }
        });
      });
    
    this.flightService.selectedCurrencyCode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedCurrencyCode) => {
        if (selectedCurrencyCode) {
          this.configurePaymentMFE(selectedCurrencyCode);
        }
      });

      this.initializeInfo();
      this.configurePaymentMFE(this.currencyCode);
  }

  private initializeInfo() {
    this.userInformation = (this.bookingService.bookingDetail.userContactInformation ?? {}) as UserInformation;
    this.userInformation.firstName = this.userInformation.firstName
      ? this.userInformation.firstName.length > AppConstants.PassengerFirstNameMaxLimit
        ? this.userInformation.firstName.substring(0, AppConstants.PassengerFirstNameMaxLimit)
        : this.userInformation.firstName.length === 1
          ? this.userInformation.firstName + '-'
          : this.userInformation.firstName
      : '--';
    this.userInformation.lastName = this.userInformation.lastName
      ? this.userInformation.lastName.length > AppConstants.PassengerLastNameMaxLimit
        ? this.userInformation.lastName.substring(0, AppConstants.PassengerLastNameMaxLimit)
        : this.userInformation.lastName.length === 1
          ? this.userInformation.lastName + '-'
          : this.userInformation.lastName
      : '--';
  }

  private configurePaymentMFE(currencyCode: string): void {
    const configuration = {
      selectedCurrency: currencyCode,
      allowPayWithMiles: false,
      isContactDetailsRequired: true,
      worldpayConfiguration: {
        baseURL: environment.worldpay.baseUrl,
        ddcTimeout: environment.worldpay.ddcTimeout,
        ddcURL: environment.worldpay.ddcUrl,
        stepUpURL: environment.worldpay.stepUpUrl
      },
      showSavedVouchers: false,
      payNowConfiguration: {
        verify: {
          retryCount: environment.worldpay.payNow.retryCount,
          interval: environment.worldpay.payNow.interval
        }
      }
    };

    this.paymentsPageService.initialize(configuration, this.userInformation, undefined);
  }

  get currencyCode(): string {
    return this.bookingService.bookingDetail.isFlightScootForSure
      ? AppConstants.DefaultCurrencyCode
      : this.sessionService.getSelectedCurrencyCode()
        ? this.sessionService.getSelectedCurrencyCode()
        : this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
