<div aria-label="Staff personal details" class="tw-flex tw-flex-wrap tw-gap-[20px]">
  <div aria-label="Staff information group" class="div-panel">
    <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex">
      <div class="tw-self-stretch tw-justify-start tw-items-center tw-gap-96 tw-inline-flex">
        <div class="tw-text-stone-900 tw-text-2xl tw-font-bold tw-font-['Montserrat'] tw-leading-9">
          {{ 'label.profile.details.personalDetails.heading.label' | translate }}
        </div>
      </div>
      <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex">
        <div class="div-info">
          <div aria-label="Staff information email group" class="div-group">
            <div aria-label="Staff information email label" class="label-text">
              {{ 'label.profile.details.email.label' | translate }}
            </div>
            <div aria-label="Staff information email data" class="info-text">
              {{ profileData?.email }}
            </div>
          </div>
          <div aria-label="Staff information title group" class="div-group">
            <div aria-label="Staff information title label" class="label-text">
              {{ 'label.profile.details.title.label' | translate }}
            </div>
            <div aria-label="Staff information title data" class="info-text">
              {{ profileData?.title }}
            </div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff information first name group" class="div-group">
            <div aria-label="Staff information first name label" class="label-text">
              {{ 'label.profile.details.firstName.label' | translate }}
            </div>
            <div aria-label="Staff information first name data" class="info-text">
              {{ profileData?.firstName }}
            </div>
          </div>
          <div aria-label="Staff information last name group" class="div-group">
            <div aria-label="Staff information last name label" class="label-text">
              {{ 'label.profile.details.lastName.label' | translate }}
            </div>
            <div aria-label="Staff information last name data" class="info-text">
              {{ profileData?.lastName }}
            </div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff information phone number group" class="div-group">
            <div aria-label="Staff information phone number label" class="label-text">
              {{ 'label.profile.details.phoneNumber.label' | translate }}
            </div>
            <div aria-label="Staff information phone number data" class="info-text gb_mask">
              {{ profileData?.phoneNumber }}
            </div>
          </div>
          <div aria-label="Staff information employee id group" class="div-group">
            <div aria-label="Staff information employee id label" class="label-text">
              {{ 'label.profile.details.employeeId.label' | translate }}
            </div>
            <div aria-label="Staff information employee id data" class="info-text">
              {{ profileData?.employeeId }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div aria-label="Staff address group" class="div-panel">
    <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex">
      <div
        aria-label="Staff address label"
        class="tw-self-stretch tw-justify-start tw-items-center tw-gap-96 tw-inline-flex"
      >
        <div class="tw-text-stone-900 tw-text-2xl tw-font-bold tw-font-['Montserrat'] tw-leading-9">
          {{ 'label.profile.details.address.heading.label' | translate }}
        </div>
      </div>
      <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex">
        <div class="div-info">
          <div aria-label="Staff county of residence group" class="div-group">
            <div aria-label="Staff county of residence label" class="label-text gb_mask">
              {{ 'label.profile.details.countryOfResidence.label' | translate }}
            </div>
            <div aria-label="Staff county of residence data" class="info-text gb_mask">
              {{ profileData?.primaryAddressCountry }}
            </div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff address line 1 group" class="div-group">
            <div aria-label="Staff address line 1 label" class="label-text gb_mask">
              {{ 'label.profile.details.streetAddressLine1.label' | translate }}
            </div>
            <div aria-label="Staff address line 1 data" class="info-text gb_mask">
              {{ profileData?.primaryAddressLine1 || '-' }}
            </div>
          </div>
          <div aria-label="Staff address line 2 group" class="div-group">
            <div aria-label="Staff address line 2 label" class="label-text gb_mask">
              {{ 'label.profile.details.streetAddressLine2.label' | translate }}
            </div>
            <div aria-label="Staff address line 2 data" class="info-text">-</div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff address line 3 group" class="div-group">
            <div aria-label="Staff address line 3 label" class="label-text gb_mask">
              {{ 'label.profile.details.streetAddressLine3.label' | translate }}
            </div>
            <div aria-label="Staff address line 3 data" class="info-text">-</div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff country group" class="div-group">
            <div aria-label="Staff country label" class="label-text gb_mask">
              {{ 'label.profile.details.countryRegion.label' | translate }}
            </div>
            <div aria-label="Staff country data" class="info-text gb_mask">
              {{ profileData?.primaryAddressCountry || '-' }}
            </div>
          </div>
          <div aria-label="Staff province group" class="div-group">
            <div aria-label="Staff province label" class="label-text gb_mask">
              {{ 'label.profile.details.stateProvince.label' | translate }}
            </div>
            <div aria-label="Staff province data" class="info-text">-</div>
          </div>
        </div>
        <div class="div-info">
          <div aria-label="Staff city group" class="div-group">
            <div aria-label="Staff city label" class="label-text gb_mask">
              {{ 'label.profile.details.cityTown.label' | translate }}
            </div>
            <div aria-label="Staff city data" class="info-text">
              {{ profileData?.city || '-' }}
            </div>
          </div>
          <div aria-label="Staff post code group" class="div-group">
            <div aria-label="Staff post code label" class="label-text gb_mask">
              {{ 'label.profile.details.postcode.label' | translate }}
            </div>
            <div aria-label="Staff post code data" class="info-text gb_mask">
              {{ profileData?.primaryAddressPostalCode }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
