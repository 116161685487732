import { Injectable } from '@angular/core';
import { ManageBookingFlightResponse, GetStaffBookingRequest, SendItineraryRequest, CancelFlightRequest } from './manage-booking.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '../shared/model/common.model';

@Injectable({
  providedIn: 'root'
})
export class ManageBookingService {
  private readonly DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  private readonly MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private readonly cancelFlight = '/booking/flight/cancel';
  private readonly bookingItinerary = '/booking/itinerary';
  private readonly bookingGet = '/external/staff/bookings/get';

  constructor(private httpClient: HttpClient) {}

  sendItinerary$(request: SendItineraryRequest[]): Observable<ResponseResult> {
    return this.httpClient.post<ResponseResult>(this.bookingItinerary, request);
  }

  getBookings$(request: GetStaffBookingRequest): Observable<ManageBookingFlightResponse> {
    return this.httpClient.post<ManageBookingFlightResponse>(this.bookingGet, request);
  }

  cancelFlight$(request: CancelFlightRequest): Observable<ResponseResult> {
    return this.httpClient.post<ResponseResult>(this.cancelFlight, request);
  }

  formatDate(dateString: any, type: string): string {
    const d = new Date(dateString);
    const day = this.DAYS[d.getDay()];
    const date = d.getDate();
    const month = this.MONTHS[d.getMonth()];

    if (type === 'date') {
      return `${day}, ${d.getDate().toString().padStart(2, '0')} ${month}`;
    } else if (type === 'time') {
      return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }
}