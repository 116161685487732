<div
  aria-label="Session expiring background"
  class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
></div>
<div aria-label="Session expiring container" class="tw-relative tw-w-full tw-max-h-full">
  <div class="tw-relative tw-rounded-5 tw-shadow dark:tw-bg-gray-700">
    <div class="tw-bg-white tw-rounded-5 tw-p-[30px]">
      <div class="tw-flex tw-flex-col tw-grow tw-gap-4">
        <div class="tw-flex tw-flex-col tw-gap-4 tw-text-center">
          <h2 aria-label="Session expiring title" class="tw-text-[26px] tw-font-bold">
            {{ 'label.session-timeout.expiring-title.text' | translate }}
          </h2>
          <span aria-label="Session expiring timer label" class="tw-text-[26px]"
            *ngIf="sessionRemainingTime$ | async as sessionRemainingTime">
            {{ sessionRemainingTime | minuteSeconds }}
          </span>
        </div>
        <div aria-label="Session expiring description container" class="tw-w-full tw-text-center">
          <span aria-label="Session expiring description label">{{
            'label.session-timeout.expiring-description.text' | translate
          }}</span
          ><br />
          <span aria-label="Session expiring description stay sign in label">{{
            'label.session-timeout.expiring-description-stay-login.text' | translate
          }}</span>
        </div>
        <div class="tw-justify-center tw-flex tw-items-center tw-mt-auto tw-gap-2.5">
          <button
            (click)="logout()"
            type="button"
            aria-label="Session expiring no button"
            class="tw-btn-secondary tw-btn xs:tw-w-auto tw-w-full tw-px-5 tw-whitespace-nowrap"
          >
            {{ 'label.session-timeout.no.text' | translate }}
          </button>
          <button
            (click)="refreshToken()"
            type="button"
            aria-label="Session expiring yes button"
            class="tw-btn-primary tw-btn xs:tw-w-auto tw-w-full tw-px-5 tw-whitespace-nowrap"
          >
            {{ 'label.session-timeout.yes.text' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
