import { Pipe, PipeTransform } from '@angular/core';
import { GenderDescriptorEnum, GenderEnum } from '../../../booking/flight/flight.enum';

@Pipe({
  name: 'genderDescriptor'
})
export class GenderDescriptorPipe implements PipeTransform {

  transform(gender: GenderEnum | undefined): string {
    return gender === 1 ? GenderDescriptorEnum.Male : (gender === 2 ? GenderDescriptorEnum.Female : GenderDescriptorEnum.None);
  } 
}
