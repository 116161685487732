import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() tooltip: string = 'Data fetching in progress';
  @Input() position: string = 'up';
  @Input() size: string = 'large';
}
